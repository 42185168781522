import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import classes from "./dashboard.module.css";
import {
  Avatar,
  Paper,
  Typography,
  Snackbar,
  Alert,
  TextField,
} from "@mui/material";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { DatePickerCalendarIcon } from "../../../components/Icons";
import {
  ATTENDANCE_PIECHART,
  LOCATION,
  LOCATION_PIECHART,
  VERIFY_HOLIDAY,
} from "../../../api-calls/api-urls";
import {
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import holidayImage from "../../../assets/images/holidayImage.png";
import { useDispatch, useSelector } from "react-redux";
import { saveLocationList } from "../../../Redux/actions/location-action";
import LocattionBoxContainer from "../Location/LocattionBoxContainer";
import { getLanguagedataFunction } from "../../../utilities/language";
import moment from "moment";
import { range } from "../../../utilities/constants";
import AlertBox from "../../../components/AlertBox";

const Dummy = () => {
  const [date, setDate] = useState();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [holiday, setHoliday] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState("");
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const {
    locationList,
    locationFilteredStatus,
    locationFilterTime,
    locationFilterMembers,
    viewAll,
    totalcount,
  } = useSelector((state) => state.reducers.location);
  const { sortName } = useSelector((state) => state.reducers.allUsers);
  const [Location, setLocation] = useState();
  const [attendance, setAttendance] = useState();
  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  // const attendance = {
  //   total: 15,
  //   present: 13,
  //   absent: 2,
  // };
  const handleEvent = (event, picker) => {
    console.log("start: ", picker.startDate._d);
    console.log("end: ", picker.endDate._d);
    setFromDate(picker.startDate._d.toISOString());
    setToDate(picker.endDate._d.toISOString());
  };
  
  useEffect(() => {
    if (fromDate !== undefined && toDate !== undefined) {
      setSelectedDate(
        `${moment(fromDate).format("DD-MM-YYYY")} to ${moment(toDate).format("DD-MM-YYYY")}`
      );
    }
    getLocationPieChartData();
    getAttendanceData();
  }, [fromDate, toDate]);

  const getAttendanceData = async () => {
    try {
      const { data } = await authApi.get(`${ATTENDANCE_PIECHART}`, {
        headers: { Authorization: getAuthorizationHeader() },
      });
      console.log(data);
      setAttendance(data);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        console.log(error.response);
        setShowAlert(true);
        setError(error.response.data.message);
      }
    }
  };

  const getLocationPieChartData = async () => {
    try {
      if (fromDate !== undefined && toDate !== undefined) {
        const { data } = await authApi.get(
          `${LOCATION_PIECHART}?start_date=${moment(fromDate).format(
            "YYYY-MM-DD"
          )}&end_date=${moment(toDate).format("YYYY-MM-DD")}`,
          {
            headers: { Authorization: getAuthorizationHeader() },
          }
        );
        setLocation(data);
      } else {
        const { data } = await authApi.get(`${LOCATION_PIECHART}`, {
          headers: { Authorization: getAuthorizationHeader() },
        });
        setLocation(data);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        console.log(error.response);
        setShowAlert(true);
        setError(error.response.data.message);
      }
    }
  };

  const getLocationData = async (userEntry) => {
    if (userInfo && userInfo.user_type === "team_leader") {
      try {
        if (fromDate !== undefined && toDate !== undefined) {
          const { data } = await authApi.post(
            `${LOCATION}?sort=${sortName}&start_date=${moment(fromDate).format(
              "YYYY-MM-DD"
            )}&end_date=${moment(toDate).format(
              "YYYY-MM-DD"
            )}&view_all=${true}`,
            userEntry,
            {
              headers: { Authorization: getAuthorizationHeader() },
            }
          );
          dispatch(saveLocationList(data));
        } else {
          const { data } = await authApi.post(
            `${LOCATION}?sort=${sortName}&view_all=${false}`,
            userEntry,
            {
              headers: { Authorization: getAuthorizationHeader() },
            }
          );
          dispatch(saveLocationList(data));
        }
      } catch (error) {
        setShowAlert(true);
        setError(error.response.data.message);
      }
    }
  };
  useEffect(() => {
    VerifyHoliday();
  }, []);

  const VerifyHoliday = async () => {
  try {
    const { data } = await authApi.get(
      `${VERIFY_HOLIDAY}/${moment(new Date()).format("YYYY-MM-DD")}`,
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );
    setHoliday(data.holiday);
  } catch (error) {
    console.log(error);
    
  }
  };

  useEffect(() => {
    if (showAlert) {
      const timeout = setTimeout(() => {
        setShowAlert(false);
      }, 3000); // Change the duration (in milliseconds) as per your requirement

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showAlert]);

  useEffect(() => {
    const filterArray = {
      status: locationFilteredStatus,
      sortby: locationFilterTime,
      members: locationFilterMembers,
    };
    console.log(filterArray);
    getLocationData(filterArray);
  }, [
    !locationList,
    locationFilteredStatus,
    locationFilterTime,
    locationFilterMembers,
    fromDate,
    toDate,
    viewAll,
    sortName,
  ]);

  return (
    <div>
      {holiday ? (
        <div>
          <Paper className={classes.holidayDashBoard}>
            <img src={holidayImage} alt="holiday" width={420} height={340} />
            <Typography className={`${classes.holidayPageImageText} mt-3`}>
              No data to show because you have marked this day as holiday.
            </Typography>
          </Paper>
        </div>
      ) : (
        <div>
          <div className={classes.datePicker1}>
            <DateRangePicker
              initialSettings={{ ranges: range }}
              alwaysShowCalendars={true}
              onApply={handleEvent}
            >
              <div>
                <TextField
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    width:"240px"
                  }}
                  size="small"
                  placeholder="Today"
                  value={selectedDate}
                />
                <span
                  className={classes.datePickerCalendarIcon}
                  style={{ right: "10px" }}
                >
                  <DatePickerCalendarIcon />
                </span>
              </div>
            </DateRangePicker>
          </div>
          {ln && (
            <Paper style={{ marginBottom: "20px" }}>
              <div className={classes.DashboardCardMaindiv}>
                <div className={classes.subdiv1}>
                  <Typography
                    className={classes.dashboardMainCardHeading}
                    style={{ textTransform: "capitalize" }}
                  >
                    {ln.attendance}
                  </Typography>
                  {attendance && (
                    <div className={classes.dashBoardPieCHartMainContainer}>
                      <div className={classes.dashBoardPieCHartSubContainer}>
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 44 44"
                          className={classes.piechartSvg}
                        >
                          <circle
                            cx="50%"
                            cy="50%"
                            fill="transparent"
                            r="15.909091"
                            stroke="#eee"
                            strokeWidth={10}
                          />
                          <circle
                            show-tip="ctDonutTip"
                            className="ct-donut-slice"
                            cx="50%"
                            cy="50%"
                            fill="transparent"
                            r="15.909091"
                            stroke="#1273B8" // Completed color
                            strokeDasharray={`${
                              (attendance.present / attendance.total) * 100
                            } ${
                              (attendance.total -
                                attendance.present / attendance.total) *
                              100
                            }`}
                            strokeWidth={10}
                            strokeDashoffset={0}
                            slice-title="Completed"
                          >
                            <animate
                              attributeName="stroke-dashoffset"
                              from={100}
                              to={0}
                              dur="0.5s"
                            />
                          </circle>
                          <circle
                            show-tip="ctDonutTip"
                            className="ct-donut-slice"
                            cx="50%"
                            cy="50%"
                            fill="transparent"
                            r="15.909091"
                            stroke="#C52A38" // Pending color
                            strokeDasharray={`${
                              (attendance.absent / attendance.total) * 100
                            } ${
                              (attendance.total -
                                attendance.absent / attendance.total) *
                              100
                            }`}
                            strokeWidth={10}
                            strokeDashoffset={
                              -(attendance.present / attendance.total) * 100
                            } // Adjusted to start from the end point of the second slice
                            slice-title="Pending"
                          >
                            <animate
                              attributeName="stroke-dashoffset"
                              from={100}
                              to={-100} // Adjusted to start from the end point of the second slice
                              dur="0.5s"
                            />
                          </circle>
                          <circle
                            cx="50%"
                            cy="50%"
                            fill="#FFFFFF"
                            r={16}
                          ></circle>
                          <text
                            x="50%"
                            y="52%"
                            className={`ct-donut-center ${classes.svg_TotalNumber}`}
                          >
                            {attendance.total}
                          </text>
                          <text
                            x="51%"
                            y="65%"
                            className={`ct-donut-center ${classes.svg_TotalText}`}
                          >
                            {ln.total}
                          </text>
                        </svg>
                        <div className="ct-tip" />
                      </div>
                      <div>
                        <div>
                          <Typography style={{ display: "flex" }}>
                            <Avatar
                              sx={{
                                bgcolor: `#1273B8`,
                                width: "13px",
                                height: "13px",
                                marginRight: "5px",
                                marginTop: "5px",
                              }}
                            >
                              {" "}
                            </Avatar>{" "}
                            {ln.present}: {attendance.present}
                          </Typography>
                        </div>
                        <div>
                          <Typography style={{ display: "flex" }}>
                            <Avatar
                              sx={{
                                bgcolor: `#C52A38`,
                                width: "13px",
                                height: "13px",
                                marginRight: "5px",
                                marginTop: "5px",
                              }}
                            >
                              {" "}
                            </Avatar>{" "}
                            {ln.absent} : {attendance.absent}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className={classes.subdiv2}>
                  <Typography className={classes.dashboardMainCardHeading}>
                    {ln.locations}
                  </Typography>
                  {Location && (
                    <div className={classes.dashBoardPieCHartMainContainer}>
                      <div className={classes.dashBoardPieCHartSubContainer}>
                        <div>
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 44 44"
                            className={classes.piechartSvg}
                          >
                            <circle
                              cx="50%"
                              cy="50%"
                              fill="transparent"
                              r="15.909091"
                              stroke="#eee"
                              strokeWidth={10}
                            />
                            <circle
                              show-tip="ctDonutTip"
                              className="ct-donut-slice"
                              cx="50%"
                              cy="50%"
                              fill="transparent"
                              r="15.909091"
                              stroke="#1273B8" // Completed color
                              strokeDasharray={`${
                                (Location.reached / Location.total) * 100
                              } ${
                                (Location.total -
                                  Location.reached / Location.total) *
                                100
                              }`}
                              strokeWidth={10}
                              strokeDashoffset={0}
                              slice-title="Completed"
                              percent-value={11}
                              usage={11}
                              total-val={20}
                            >
                              <animate
                                attributeName="stroke-dashoffset"
                                from={100}
                                to={0}
                                dur="0.5s"
                              />
                            </circle>
                            <circle
                              show-tip="ctDonutTip"
                              className="ct-donut-slice"
                              cx="50%"
                              cy="50%"
                              fill="transparent"
                              r="15.909091"
                              stroke="#240051" // Pending color
                              strokeDasharray={`${
                                (Location.pending / Location.total) * 100
                              } ${
                                (Location.total -
                                  Location.pending / Location.total) *
                                100
                              }`}
                              strokeWidth={10}
                              strokeDashoffset={
                                -(Location.reached / Location.total) * 100
                              } // Adjusted to start from the end point of the second slice
                              slice-title="Pending"
                              percent-value={2}
                              usage={2}
                              total-val={20}
                            >
                              <animate
                                attributeName="stroke-dashoffset"
                                from={100}
                                to={-100} // Adjusted to start from the end point of the second slice
                                dur="0.5s"
                              />
                            </circle>
                            <circle
                              show-tip="ctDonutTip"
                              className="ct-donut-slice"
                              cx="50%"
                              cy="50%"
                              fill="transparent"
                              r="15.909091"
                              stroke="#C52A38" // Rejected color
                              strokeDasharray={`${
                                (Location.rejected / Location.total) * 100
                              } ${
                                (Location.total -
                                  Location.rejected / Location.total) *
                                100
                              }`}
                              strokeWidth={10}
                              strokeDashoffset={
                                -(
                                  (Location.reached + Location.pending) /
                                  Location.total
                                ) * 100
                              } // Adjusted to start from the end point of the second slice
                              slice-title="Rejected"
                              percent-value={7}
                              usage={7}
                              total-val={20}
                            >
                              <animate
                                attributeName="stroke-dashoffset"
                                from={100}
                                to={-100} // Adjusted to start from the end point of the second slice
                                dur="0.5s"
                              />
                            </circle>
                            <circle
                              cx="50%"
                              cy="50%"
                              fill="#FFFFFF"
                              r={16}
                            ></circle>
                            <text
                              x="50%"
                              y="52%"
                              className={`ct-donut-center ${classes.svg_TotalNumber}`}
                            >
                              {Location.total}
                            </text>
                            <text
                              x="51%"
                              y="65%"
                              className={`ct-donut-center ${classes.svg_TotalText}`}
                            >
                              {ln.total}
                            </text>
                          </svg>
                          <div className="ct-tip" />
                        </div>
                      </div>
                      <div>
                        <div>
                          <Typography style={{ display: "flex" }}>
                            <Avatar
                              sx={{
                                bgcolor: `#1273B8`,
                                width: "13px",
                                height: "13px",
                                marginRight: "5px",
                                marginTop: "5px",
                              }}
                            >
                              {" "}
                            </Avatar>{" "}
                            {ln.reached} : {Location.reached}
                          </Typography>
                        </div>
                        <div>
                          <Typography style={{ display: "flex" }}>
                            <Avatar
                              sx={{
                                bgcolor: `#240051`,
                                width: "13px",
                                height: "13px",
                                marginRight: "5px",
                                marginTop: "5px",
                              }}
                            >
                              {" "}
                            </Avatar>{" "}
                            {ln.pending} : {Location.pending}
                          </Typography>
                        </div>
                        <div>
                          <Typography style={{ display: "flex" }}>
                            <Avatar
                              sx={{
                                bgcolor: `#C52A38`,
                                width: "13px",
                                height: "13px",
                                marginRight: "5px",
                                marginTop: "5px",
                              }}
                            >
                              {" "}
                            </Avatar>{" "}
                            {ln.rejected} : {Location.rejected}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Paper>
          )}
          <div>
            <LocattionBoxContainer locationList={locationList} />
          </div>
          {showAlert && error && (
            <AlertBox type="error" message={error} stateName={setShowAlert} />
          )}
        </div>
      )}
    </div>
  );
};

export default Dummy;
