import React, { useEffect, useState } from "react";
import classes from "./index.module.css";
import { Form } from "react-bootstrap";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { pageLoader } from "../../../Redux/actions/user-actions";
import clapImage from "../../../assets/images/img_008_great.png";
import {
  api,
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";
import "../../../assets/css/style.css";
import "../../../assets/css/bootstrap.min.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  MY_SUBS,
  MY_SUBSCRIPTION,
  MY_SUBS_AVAIL,
  PAYMENT_SUCCESS,
  REFERAL_SUBSCRIPTION,
  RENEW_SUBS,
  SUBSCRIPTION_LIST,
  UPDATE_REFERALS,
} from "../../../api-calls/api-urls";
import { useDispatch, useSelector } from "react-redux";
import { getLanguagedataFunction } from "../../../utilities/language";
import { generateColor } from "../../../utilities/commonFuntions";
import moment from "moment";
import ConfirmationModal from "../../Home/Subscription/ConfirmationModal";

const RegisteredSuccessfully = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rootRef = React.useRef(null);
  const [page, setPage] = useState(true);
  const [oneSubsc, setOneSubsc] = useState();
  const [modalToggle, setModalToggle] = useState(false);
  const [modalToggle1, setModalToggle1] = useState(false);
  const [plans, setPlans] = useState();
  const [selectedValue, setSelectedValue] = React.useState();

  useEffect(() => {
    const path = window.location.pathname;
    if (path.includes("/select-subscription")) {
      setPage(false);
    } else if (path.includes("/referal-subscription")) {
      setPage(false);
    } else if (path.includes("/upgrade-plan")) {
      setPage(false);
      const path = window.location.pathname.split("/");
      getCustomerSubscription(path[path.length - 1]);
    }
  }, []);

  const getCustomerSubscription = async (id) => {
    const { data } = await authApi.get(`${MY_SUBS}/get-one/${id}`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    console.log(data);
    setOneSubsc(data);
  };

  const closeModal1 = () => {
    setModalToggle1(false);
    navigate("/subscriptions");
  };

  const getPlansDetails = async () => {
    const { data } = await api.get(SUBSCRIPTION_LIST);
    setPlans(data);
    if (data && data.length > 0 && data[0].durations) {
      setSelectedValue(data[0].durations[0]._id);
    }
  };

  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  useEffect(() => {
    if (!plans) {
      getPlansDetails();
    }
  }, [!plans]);

  const handleChange = (value) => {
    setSelectedValue(value);
  };
  function closeModal() {
    setModalToggle(false);
  }

  const posibleUpgrade = (member, duration) => {
    const cur_point = oneSubsc?.no_team_members * oneSubsc?.duration;
    const new_point = member * duration;
    if (cur_point < new_point) {
      return true;
    } else {
      return false;
    }
  };

  const getSubsription = async () => {
    var result = {};
    await authApi
      .get(MY_SUBS_AVAIL, {
        headers: { Authorization: getAuthorizationHeader() },
      })
      .then(function (res) {
        //dispatch(SaveAvailableSubc(res.data));
        result = res.data[res.data.length - 1];
        console.log(result);
      });
    return result;
  };

  const handleClick = async (item) => {
    if (window.location.pathname.includes("/referal-subscription")) {
      const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      const referal_data = JSON.parse(sessionStorage.getItem("referal_data"));
      const no_of_days = Math.floor(
        referal_data.referal_points / item.noOfTeamMembers
      );
      const userEntry = {
        subscription_id: item._id,
        user_id: userInfo._id,
        referal_id: referal_data.referal_id,
        no_of_days: no_of_days,
      };
      console.log(userEntry);
      const { data } = await authApi.post(REFERAL_SUBSCRIPTION, userEntry, {
        headers: { Authorization: getAuthorizationHeader() },
      });
      MakePointsNull(referal_data.referal_id);
      await sessionStorage.removeItem("referal_data");
      navigate("/subscriptions");
    } else {
      const userEntry = {
        subscriptionid: item._id,
        durationid: selectedValue,
      };
      if (window.location.pathname.includes("upgrade-plan")) {
        const path = window.location.pathname.split("/");
        userEntry.customer_sub_id = path[path.length - 1];
      }
      dispatch(pageLoader(true));
      const { data } = await authApi.post(MY_SUBSCRIPTION, userEntry, {
        headers: { Authorization: getAuthorizationHeader() },
      });
      console.log(data);
      if (
        data.data &&
        data.data.order_items &&
        data.data.order_items.length > 0
      ) {
        const userEntry2 = {
          userid: data.data.user_id,
          orderid: data.data._id,
        };
        if (window.location.pathname.includes("upgrade-plan")) {
          const path = window.location.pathname.split("/");
          userEntry2.customer_sub_id = path[path.length - 1];
          userEntry2.type = "upgrade";
        }
        const data2 = await authApi.post(PAYMENT_SUCCESS, userEntry2, {
          headers: { Authorization: getAuthorizationHeader() },
        });

        if (window.location.pathname.includes("upgrade-plan")) {
          const availsubs = await getSubsription();
          console.log(availsubs);
          const team_id = sessionStorage.getItem("team_id");
          if ((data2.data && availsubs, team_id)) {
            const userEntry3 = {
              team_id: team_id,
              avail: availsubs,
              type: "upgrade",
            };
            const data3 = await authApi.post(RENEW_SUBS, userEntry3, {
              headers: { Authorization: getAuthorizationHeader() },
            });
            console.log(data3.data);
            sessionStorage.removeItem("team_id");
          }
        }
      }

      if (page) {
        navigate("/payment_success");
      } else if (window.location.pathname.includes("upgrade-plan")) {
        setModalToggle1(true);
      } else {
        navigate("/my-team/create-team");
      }
    }
  };

  const getDiscountAmount = (member, duration, price) => {
    const cure_Per =
      oneSubsc?.duration_details.price.$numberDecimal / oneSubsc?.duration;
    const expiryDate = moment(oneSubsc.end_date); //no of remaing days as int

    const today = moment(); //today's date
    let remainingDays = expiryDate.diff(today, "days");
    console.log(remainingDays);
    const discount_amt = remainingDays * cure_Per;
    console.log(price - discount_amt);
    const amount = price - discount_amt;
    return amount.toFixed(2);
  };

  const MakePointsNull = (id) => {
    const { data } = authApi.put(
      `${UPDATE_REFERALS}/${id}`,
      {},
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );
    console.log(data);
  };

  return (
    <div className="account-pages">
      {ln ? (
        <div className="container min-vh-100 py-sm-5">
          {/* <!-- start page title --> */}
          {page ? (
            <div className="row mt-0 mb-5">
              <div className="col-xxl-12 col-lg-12">
                <div className="text-center bg-transparent">
                  <>
                    <div className="avatar-xl mx-auto mb-3 bg-transparent">
                      <div
                        className={`avatar-title ${classes.regSuccessImageContainer}`}
                      >
                        <img
                          src={clapImage}
                          alt=""
                          className={classes.regSuccessImage}
                        />
                        {/* <svg
                          width="100"
                          height="100"
                          viewBox="0 0 64 64"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          className="iconify iconify--emojione"
                        >
                          <path
                            d="M47.5 27.3S43 23.6 40.6 20c-.9-1.3-3.8-1.1-4.8 1.4l-15.6 6-10.3-5.5c-1.6-1-3.7-.3-4.6 1.6-.9 1.8-.4 4.1 1.3 5.1l1.6.9c-1.4-.2-2.9.5-3.6 1.9-.9 1.8-.4 4.1 1.3 5.1l3.6 2.1c-1.6-1-3.7-.3-4.6 1.6-.9 1.8-.4 4.1 1.3 5.1 0 0 18.1 11.2 24.3 14.5 4.9 2.7 14.6 4 20.7-3.4 2-2.6-3.7-29.1-3.7-29.1"
                            fill="#b58360"
                          />
                          <g fill="#fed0ac">
                            <path d="M55 27.3c-3.2-2.2-5.4-5.1-7-9.5-.6-1.5-2.8-1.8-4.1.7-1.9 3.6-.1 6.5.9 7.8l.5.6s.3 1.1.8 2.4c-2.7-.6-5.7.9-7.8 3.7-2.2 2.9-7.4 7.9-9.6 10.8-2.7 3.5-2 8.5 1 10.7 0 0 15.7 11.5 26.1-2.6 8.2-11 2.4-22.4-.8-24.6" />
                            <path d="M49.7 31.5c1.6 1.2 2 3.5.8 5.3-1.1 1.8-3.4 2.3-5 1.1L20.6 20c-1.6-1.2-2-3.5-.8-5.3 1.1-1.8 3.4-2.3 5-1.1l24.9 17.9" />
                          </g>
                          <path
                            d="M36.8 29.5 20.9 18c-.9-.6-1.5-1.6-1.2-3.1-1.1 2-.4 4 .9 5l15.9 11.5c1.6 1.2 1.9-.7.3-1.9"
                            fill="#e0a372"
                          />
                          <path
                            d="M41.6 35c1.6 1.2 2 3.5.8 5.3-1.1 1.8-3.4 2.3-5 1.1l-24.9-18c-1.6-1.2-2-3.5-.8-5.3 1.1-1.8 3.4-2.3 5-1.1l24.9 18"
                            fill="#fed0ac"
                          />
                          <path
                            d="M32 35.4 12.8 21.5c-.9-.6-1.5-1.8-1.2-3.1-1.1 2-.5 4.2.9 5.2l19.3 13.7c1.5 1.2 1.8-.8.2-1.9"
                            fill="#e0a372"
                          />
                          <path
                            d="M40 43c1.6 1.2 2 3.5.8 5.3-1.1 1.8-3.4 2.3-5 1.1l-24.9-18c-1.6-1.2-2-3.5-.8-5.3 1.1-1.8 3.4-2.3 5-1.1L40 43"
                            fill="#fed0ac"
                          />
                          <path
                            d="m28.2 41.8-17-12.2c-.9-.6-1.5-2-1.1-3.4-1.3 1.9-.8 4.4.6 5.4l17.2 12.1c1.6 1.2 1.9-.7.3-1.9"
                            fill="#e0a372"
                          />
                          <path
                            d="M39.3 52c1.6 1.2-9.8 2.4-11.4 1.2L10.3 40.5c-1.6-1.2-2-3.5-.8-5.3 1.1-1.8 3.4-2.3 5-1.1L39.3 52"
                            fill="#fed0ac"
                          />
                          <path
                            d="M59.9 41.6s-1.7 8.8-8.7 14.1c-6.2 4.7-15.8.6-20.4-2.4C26 50 23.6 48.4 12 39.6c-2.2-1.7-2.8-2.8-2.5-4.6-1.7 1.8-.8 4.6.6 5.6l1.2.8s13.9 10.2 18.6 13.4c4.3 3 15.4 7.7 22.4 2.1 7.9-6.1 7.6-15.3 7.6-15.3M48 30.1C46 28 45.9 25 45.9 25l-.5-.6c-.8-1-2.1-3.1-1.6-5.6-1.9 3.5 0 6.3.9 7.5l.5.6s-.1.9.4 1.6l2.4 1.6"
                            fill="#e0a372"
                          />
                          <path
                            d="m37.8 2 3 12.2 5.9-11.3zm14.8 3.6-4.5 10.1L58.6 13zM30.2 3.5l2.8 11-9.2-5.9z"
                            fill="#42ade2"
                          />
                        </svg> */}
                      </div>
                    </div>
                    <Typography className={classes.regSuccessHeading}>
                      {ln.registered_successfully}
                    </Typography>
                    <Typography className={classes.loginDescription}>
                      {ln.account_created_successfully}
                    </Typography>
                    <Typography
                      className={classes.videoModalBtn}
                      onClick={() => setModalToggle(true)}
                    >
                      {ln.demo_video}
                    </Typography>
                  </>

                  {/* <!-- Default Modals --> */}
                  <Modal
                    open={modalToggle}
                    onClose={() => closeModal()}
                    aria-labelledby="server-modal-title"
                    data-testid="register_modal"
                    aria-describedby="server-modal-description"
                    sx={{
                      display: "flex",
                      height: 300,
                      marginTop: 10,
                      justifyContent: "center",
                    }}
                    container={() => rootRef.current}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        bgcolor: "background.paper",
                        borderRadius: 1,
                        boxShadow: (theme) => theme.shadows[5],
                      }}
                      style={{ width: "70%", height: "575px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          size="small"
                          onClick={() => closeModal()}
                          variant="text"
                        >
                          <CloseIcon />
                        </Button>
                      </div>
                      <div className="modal-dialog bg-transparent modal-lg">
                        <div className="modal-content bg-transparent border-0">
                          <div className="modal-body bg-transparent">
                            <video controls autoPlay width="100%" height="">
                              <source
                                type="video/mp4"
                                src="https://endtest-videos.s3-us-west-2.amazonaws.com/documentation/endtest_data_driven_testing_csv.mp4"
                              />
                            </video>
                          </div>
                        </div>
                        {/* <!-- /.modal-content --> */}
                      </div>
                    </Box>
                  </Modal>

                  <div
                    id="myModal"
                    className="modal fade"
                    tabIndex="-1"
                    aria-labelledby="myModalLabel"
                    aria-hidden="true"
                    style={{ display: "none" }}
                  >
                    {/* <!-- /.modal-dialog --> */}
                  </div>
                  {/* <!-- /.modal --> */}
                </div>
              </div>
            </div>
          ) : null}
          {/* <!-- end page title --> */}

          {plans && plans.length > 0 ? (
            <div className="row">
              <Typography className={classes.regSuccessDescription}>
                {ln.select_your_package}
              </Typography>
              {/* <!-- end col --> */}
              {plans.map((plan, index) => {
                return (
                  <div className="col-xl-4" key={index}>
                    <div className="card" style={{height: "100%"}}>
                      <div
                        className={`card-header`}
                        style={{
                          borderBottom: `4px solid ${generateColor(
                            plan.packageName
                          )}`,
                          background: `${generateColor(plan.packageName)}66`,
                          borderRadius: "8px 8px 0px 0px",
                        }}
                      >
                        <div className="d-flex align-items-start mb-0">
                          <div className="flex-grow-1">
                            <Typography className={classes.subsCardHeading}>
                              {plan.packageName}
                            </Typography>
                          </div>
                        </div>
                      </div>
                      {/* <!-- end card header --> */}

                      <div className="card-body">
                        <dl className="row mb-0">
                          <div className="col-xl-12 text1">
                            <span
                              className={`${classes.videoModalBtn} ${classes.subsCardSubHeading}`}
                            >
                              {ln.duration}:
                            </span>
                            <div className={classes.durationsContainer}>
                              {plan &&
                                plan.durations &&
                                plan.durations.length > 0 &&
                                !window.location.pathname.includes(
                                  "referal-subscription"
                                ) &&
                                plan.durations.map((item, index) => {
                                  return (
                                    <div
                                      className={classes.durationRadio}
                                      key={index}
                                    >
                                      <Form.Check
                                        value={item._id}
                                        type="radio"
                                        aria-label="radio 1"
                                        key={item.id}
                                        disabled={
                                          !posibleUpgrade(
                                            plan.noOfTeamMembers,
                                            item.days
                                          ) && oneSubsc
                                        }
                                        checked={selectedValue === item._id}
                                        onChange={() => handleChange(item._id)}
                                      />
                                      <Typography
                                        className={`${classes.videoModalBtn} ${classes.subsCardSubHeading}`}
                                        style={{
                                          textDecoration: oneSubsc
                                            ? "line-through"
                                            : "none",
                                        }}
                                      >
                                        ₹ {item.price}
                                        <span
                                          className={classes.login_form_lable}
                                        >
                                          /{ln[item?.duration]}
                                        </span>
                                      </Typography>
                                      {oneSubsc &&
                                        posibleUpgrade(
                                          plan.noOfTeamMembers,
                                          item.days
                                        ) && (
                                          <Typography
                                            className={`${classes.videoModalBtn} ${classes.subsCardSubHeading}`}
                                          >
                                            ₹{" "}
                                            {getDiscountAmount(
                                              plan.noOfTeamMembers,
                                              item.days,
                                              item.price
                                            )}
                                            <span
                                              className={
                                                classes.login_form_lable
                                              }
                                            >
                                              /{ln[item?.duration]}
                                            </span>
                                          </Typography>
                                        )}
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                          <div className={classes.exeText}>
                            <span
                              className={`${classes.videoModalBtn} ${classes.subsCardSubHeading}`}
                            >
                              {ln.exe_gst}
                            </span>
                          </div>
                          <hr className="style1" />

                          <div className={classes.secondSection}>
                            <div
                              className={`${classes.videoModalBtn} ${classes.subsCardSubHeading}`}
                              style={{ width: "75%" }}
                            >
                              {ln.members_can_add} :
                            </div>
                            <div
                              className={`${classes.login_form_lable}`}
                              style={{ width: "25%", textAlign: "right" }}
                            >
                              {ln.upto} {plan.noOfTeamMembers}
                            </div>
                          </div>
                          <hr className="style1" />

                          <div className="col-xxl-12 col-xl-12">
                            <span
                              className={`${classes.videoModalBtn} ${classes.subsCardSubHeading}`}
                            >
                              {ln.features}
                            </span>
                            {plan.features && plan.features.length > 0 ? (
                              <div className="border-0">
                                <ul className="list-group border-0">
                                  {plan.features.map((item, index) => {
                                    return (
                                      <li
                                        key={index}
                                        className={`${classes.FeaturesList} list-group-item border-0 p-1`}
                                      >
                                        <svg
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <rect
                                            width="24"
                                            height="24"
                                            rx="12"
                                            fill={`${generateColor(
                                              plan.packageName
                                            )}66`}
                                          />
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M17.8162 8.20701C18.0701 8.47369 18.0597 8.89568 17.793 9.14954L10.0893 16.4829C9.95773 16.6081 9.78078 16.6742 9.59933 16.666C9.41788 16.6577 9.24766 16.5758 9.12803 16.4391L6.16506 13.0545C5.92254 12.7775 5.95052 12.3563 6.22755 12.1138C6.50459 11.8713 6.92577 11.8992 7.16829 12.1763L9.67342 15.0379L16.8737 8.1838C17.1404 7.92994 17.5624 7.94033 17.8162 8.20701Z"
                                            fill={generateColor(
                                              plan.packageName
                                            )}
                                          />
                                        </svg>
                                        <span
                                          className={classes.login_form_lable}
                                        >
                                          {item.feature}
                                        </span>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            ) : null}

                            {/* <!-- end card-body --> */}
                          </div>
                        </dl>
                      </div>
                      {/* <!-- end card-body -->
              <!-- end card-body --> */}
                      <div className="card-footer">
                        <Button
                          variant="contained"
                          fullWidth
                          color="success"
                          style={{
                            textTransform: "capitalize",
                          }}
                          onClick={() => handleClick(plan)}
                        >
                          {ln.but_this}
                        </Button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
          {/* <!-- end row --> */}

          {/* <!-- end row --> */}
        </div>
      ) : null}

      {modalToggle1 && (
        <ConfirmationModal closeModal={closeModal1} from="upgrade" />
      )}
    </div>
  );
};

export default RegisteredSuccessfully;
