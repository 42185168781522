import React, { useState } from "react";
import classes from "./location.module.css";
import { Typography, Button } from "@mui/material";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { FaList } from "react-icons/fa";
import MyTableBody from "../../../components/LocationListView";
import { getLanguagedataFunction } from "../../../utilities/language";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SaveViewAll } from "../../../Redux/actions/location-action";
import LocationCard from "./locationCard";

const LocattionBoxContainer = ({ locationList, viewAll, fromDate, toDate,setUpdateState }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState("grid");
  const ln = useSelector((state) => state.reducers.allUsers.language_json);
  // const { locationList, locationFilteredStatus, locationFilterTime, locationFilterMembers, viewAll } =
  //   useSelector((state) => state.reducers.location);

  React.useEffect(() => {
    if (!ln) {
      getLanguagedataFunction(dispatch);
    }
  }, [dispatch, ln]);

  const handleViewAll = (e) => {
    e.stopPropagation();
    navigate("/location");
    dispatch(SaveViewAll(true));
  };

  const handleCardClick = (e, item) => {
    const name = item.slug.toLowerCase();
    navigate(`/location/${name}`);
    e.preventDefault();
  };

  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  return (
    <div>
      {ln ? (
        <div>
          <div className={classes.viewstyleContainer}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography className={classes.viewstyleText}>
                {ln.recent_locations} ({locationList?.length})
              </Typography>
              <div className={classes.viewbittonContainer}>
                <Button
                  className={`${classes.button} ${
                    activeButton === "grid" ? `${classes.active}` : ""
                  }`}
                  onClick={() => handleButtonClick("grid")}
                >
                  <BsFillGrid3X3GapFill />
                </Button>
                <Button
                  className={`${classes.button} ${
                    activeButton === "list" ? `${classes.active}` : ""
                  }`}
                  onClick={() => handleButtonClick("list")}
                >
                  <FaList />
                </Button>
              </div>
            </div>
            <div>
              {window.location.pathname === "/home" && (
                <Button
                  variant="text"
                  style={{ textTransform: "none" }}
                  onClick={(e) => handleViewAll(e)}
                >
                  {viewAll ? ln.viewless : ln.viewall}
                </Button>
              )}
            </div>
          </div>
          {activeButton === "grid" ? (
            <div>
              <div className={classes.GridContainer}>
                {
                  locationList.length > 0 ?
                  locationList.map((item, index) => {
                    // const isOnline = findOnline(item.teamMember[0]._id);
                    // console.log(isOnline);
                    return (
                      <LocationCard
                        key={index}
                        item={item}
                        key={index}
                        index={index}
                        handleCardClick={handleCardClick}
                        ln={ln}
                        fromDate={fromDate}
                        toDate={toDate}
                        setUpdateState={setUpdateState}
                      />
                    );
                  }):"No data found"}

              </div>
            </div>
          ) : (
            <div>
              <MyTableBody list={locationList}   handleCardClick={handleCardClick} />
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default LocattionBoxContainer;
