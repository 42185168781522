import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classes from "./location.module.css";
import WaveSurfer from "wavesurfer.js";
import { FaPlay, FaPause } from "react-icons/fa";
import { Button, Typography } from "@mui/material";

const Waveform = ({ audio }) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const waveSurferRef = useRef(null);

  useEffect(() => {
    const waveSurfer = WaveSurfer.create({
      container: "#waveform",
      hideScrollbar: true,
      waveColor: "#CDD5E5",
      progressColor: "blue",
      cursorColor: "black",
      barWidth: 3,
      barHeight: 10,
      minPxPerSec: 10,
      barGap: 2,
      height: 18,
    });

    waveSurfer.load(audio);
    waveSurferRef.current = waveSurfer;

    waveSurfer.on("ready", () => {
      setDuration(waveSurfer.getDuration());
    });

    waveSurfer.on("audioprocess", () => {
      setCurrentTime(waveSurfer.getCurrentTime());
    });

    waveSurfer.on("play", () => {
      setIsPlaying(true);
    });

    waveSurfer.on("pause", () => {
      setIsPlaying(false);
    });

    return () => {
      waveSurfer.destroy();
    };
  }, [audio]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handlePlayPause = () => {
    if (waveSurferRef.current) {
      if (isPlaying) {
        waveSurferRef.current.pause();
      } else {
        waveSurferRef.current.play();
      }
    }
  };

  return (
    <div className={classes.AudioPlayerContainer}>
      <Button
        className={classes.AudioPlayerBtn}
        onClick={handlePlayPause}
        type="button"
        style={{ height: "18px" }}
      >
        {isPlaying ? <FaPause /> : <FaPlay />}
      </Button>
      <div id="waveform" className={classes.waveForm} />
      <Typography className={classes.audioTimerText}>
        {formatTime(currentTime)}/{formatTime(duration)}
      </Typography>
    </div>
  );
};

Waveform.propTypes = {
  audio: PropTypes.string.isRequired,
};

export default Waveform;
