import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  InputAdornment,
  Typography,
  TextField,
} from "@mui/material";
import { MdClose } from "react-icons/md";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Box from "@mui/material/Box";
import successimage from "../../../assets/images/sucessImge.png";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import classes from "./location.module.css";
import moment from "moment";
import {
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import { LOCATION } from "../../../api-calls/api-urls";
import { saveLocationList } from "../../../Redux/actions/location-action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getLanguagedataFunction } from "../../../utilities/language";
import DatePicker from "react-datepicker";
import {
  DatePickerCalendarIcon,
  TimePickerIcon,
} from "../../../components/Icons";
import AlertBox from "../../../components/AlertBox";

const AssignLaterModel = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalToggle, setModalToggle] = useState(true);
  const rootRef = React.useRef(null);
  const [update, setUpdate] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState("");
  const [date, setDate] = React.useState();
  const [time, setTime] = useState();
  const [success, setSuccess] = useState(false);
  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  useEffect(() => {
    if (props.success) {
      setSuccess(true);
    }
  }, [props.success]);

  function closeModal(message) {
    setModalToggle(false);
    if (success) {
      navigate("/location");
      props.closeModal(false, true);
      getLocationData();
    } else {
      props.closeModal(false, false);
    }
  }

  const getAvailableTimes = () => {
    const selectedDate = moment(new Date(date)).format("YYYY-MM-DD");
    const currentDateTime = moment(new Date()).format("YYYY-MM-DD");

    if (selectedDate === currentDateTime) {
      console.log("get oin this");
      const currentHour = new Date().getHours();
      const currentMinute = new Date().getMinutes();

      // Create an array of time options for the next 24 hours
      const timeOptions = [];
      for (let hour = currentHour; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 15) {
          // Exclude past times
          if (
            hour > currentHour ||
            (hour === currentHour && minute >= currentMinute)
          ) {
            const time = new Date();
            time.setHours(hour);
            time.setMinutes(minute);
            timeOptions.push(time);
          }
        }
      }

      return timeOptions;
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (window.location.pathname.includes("update")) {
      setUpdate(true);
    }
  }, [window.location.pathname]);

  const getLocationData = async () => {
    const userEntry = {};
    const { data } = await authApi.post(LOCATION, userEntry, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    dispatch(saveLocationList(data));
    navigate("/location");
  };

  const handleSubmit = async () => {
    if (date && time) {
      const isoDate = moment(date).format("YYYY-MM-DD");
      const isoTime = moment(time.$d).format("HH:mm:ss");
      const assigntime = new Date(isoDate + " " + isoTime);
      // console.log("isoDate", assigntime);
      // const isoDateTime = `${isoDate}T${isoTime}`;
      // const originalMoment = moment(isoDateTime);
      // console.log(originalMoment);
      // const modifiedIsoDateTime = originalMoment.format("YYYY-MM-DDTHH:mm:ss");
      // console.log(modifiedIsoDateTime);
      let UserEntry = props.UserEntry;
      UserEntry.assigned_on = new Date(assigntime);
      console.log(UserEntry);
      const isFuture = moment(assigntime).isAfter();
      if (isFuture) {
        if (props.editLocation) {
          try {
            const { data } = await authApi.put(
              `${LOCATION}/${props.editLocation._id}`,
              UserEntry,
              {
                headers: { Authorization: getAuthorizationHeader() },
              }
            );
          } catch (error) {
            console.log(error);
            setShowAlert(true);
            setError(error.response.data.message);
            return;
          }
        } else {
          try {
            const { data } = await authApi.post(
              `${LOCATION}/create`,
              UserEntry,
              {
                headers: { Authorization: getAuthorizationHeader() },
              }
            );
          } catch (error) {
            console.log(error);
            setShowAlert(true);
            setError(error.response.data.message);
            return;
          }
        }
        setSuccess(true);
      } else {
        setShowAlert(true);
        setError("Please select future date and time");
      }
    }
  };
  return (
    <div>
      {ln ? (
        <Modal
          open={modalToggle}
          onClose={() => closeModal()}
          aria-labelledby="server-modal-title"
          data-testid="register_modal"
          aria-describedby="server-modal-description"
          sx={{
            display: "flex",
            height: 480,
            marginTop: 10,
            justifyContent: "center",
          }}
          container={() => rootRef.current}
        >
          <Box
            sx={{
              position: "relative",
              bgcolor: "background.paper",
              borderRadius: 1,
              boxShadow: (theme) => theme.shadows[5],
            }}
            style={{
              width: "max-content",
              minWidth: "460px",
              height: "fit-content",
            }}
            className={classes.loginModalWidth}
          >
            <Button
              variant="text"
              sx={{ minWidth: "unset" }}
              className={classes.closebtn}
              onClick={() => closeModal()}
            >
              <MdClose />
            </Button>
            {!success ? (
              <div>
                <div className={classes.AddMemberHeading}>
                  <Typography>{ln.assign_later}</Typography>
                </div>
                <ValidatorForm
                  useref="form"
                  onSubmit={handleSubmit}
                  className={classes.modalmain}
                >
                  <div className={classes.depFormContainer}>
                    <div>
                      <Typography className={classes.form_lable}>
                        {ln.date}
                      </Typography>
                      <div
                        className={classes.datePicker1}
                        style={{ width: "100%", marginTop: "10px" }}
                      >
                        <DatePicker
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            "cursor": "pointer",
                          }}
                          size="small"
                          selected={date}
                          minDate={new Date()}
                          onChange={(date) => setDate(date)}
                          placeholderText="Today"
                          dateFormat="yyyy-MM-dd"
                          className={classes.datePicker}
                        />
                        <span
                          className={classes.datePickerCalendarIcon}
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "10px",
                            transform: "translateY(-50%)",
                            cursor: "text",
                          }}                          
                        >
                          <DatePickerCalendarIcon  className={classes.icon}/>
                        </span>
                      </div>
                    </div>
                    <div>
                      <Typography className={classes.form_lable}>
                        {ln.time}
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div
                          className={classes.timePickerContainer}
                          style={{ width: "100%", marginTop: "10px" }}
                        >
                          <MobileTimePicker
                            sx={{
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                              width: "100%",
                              cursor: "pointer",
                            }}
                            value={time}
                            className={classes.timePicker}
                            onChange={(newValue) => setTime(newValue)}
                          />
                          <span
                            className={classes.datePickerCalendarIcon}
                            style={{ right: "10px" }}
                          >
                            <TimePickerIcon />
                          </span>
                        </div>
                      </LocalizationProvider>
                    </div>
                    <div className={classes.addMemberModalbtnSection}>
                      <Button variant="outlined" onClick={() => closeModal()}>
                        {ln.cancel}
                      </Button>
                      <Button variant="contained" type="submit">
                        Assign
                      </Button>
                    </div>
                  </div>
                </ValidatorForm>
              </div>
            ) : (
              <div className={classes.addMembersuccess}>
                <img
                  src={successimage}
                  alt="success"
                  width="auto"
                  height="auto"
                />
                <Typography className={classes.imagePageText1}>
                  {update
                    ? "Location Updated SuccessFully"
                    : "Location Assigned"}
                </Typography>
                <Typography className={classes.successPageSubText}>
                  Location has been assigned to <b>{props.teamMemberName}</b>{" "}
                  for <b>{props.title}</b>{" "}
                  {!props.success &&
                    `on ${moment(date).format("DD[th] MMM YYYY")}`}
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => closeModal()}
                  style={{
                    textTransform: "capitalize",
                    width: "125px",
                    padding: "11px 24px",
                  }}
                >
                  okay
                </Button>
              </div>
            )}
          </Box>
        </Modal>
      ) : null}
      {showAlert && error && (
        <AlertBox type="error" message={error} stateName={setShowAlert} />
      )}
    </div>
  );
};

export default AssignLaterModel;
