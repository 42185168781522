import React, { useState, useEffect } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { Box, Button } from "@mui/material";
import classes from "./location.module.css";
import { BiLeftArrow } from "react-icons/bi";
import { FaArrowLeft } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
const AddressMapLocation = ({
  mapLocation,
  setMapLocation,
  setIsMapScreen,
}) => {
  const [marker, setMarker] = useState(null);
  const [centerPoint, setCenterPoint] = useState({ lat: null, lng: null });
  const [locality, setLocality] = useState("");
  const [address, setAddress] = useState("");

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
  });

  // Parse existing mapLocation URL to extract coordinates
  useEffect(() => {
    if (mapLocation) {
      const url = new URL(mapLocation);
      const [lat, lng] = url.search.replace("?q=", "").split(",").map(Number);
      if (lat && lng) {
        setMarker({ lat, lng });
        setCenterPoint({ lat, lng });
        fetchAddress(lat, lng);
      }
    } else if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCenterPoint({ lat: latitude, lng: longitude });
        },
        () => {
          alert("Failed to get your location.");
        }
      );
    } else {
      alert("Geolocation is not available in your browser.");
    }
  }, [mapLocation]);

  const fetchAddress = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`
      );
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        setAddress(data.results[0].formatted_address);
        const localityResult = data.results[0].address_components.find(
          (component) => component.types.includes("locality")
        );
        setLocality(
          localityResult ? localityResult.long_name : "Unknown locality"
        );
      } else {
        setAddress("Address not found");
        setLocality("Unknown locality");
      }
    } catch (error) {
      console.error("Error fetching address:", error);
      setAddress("Error fetching address");
      setLocality("Error fetching locality");
    }
  };

  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    const newUrl = `https://www.google.com/maps?q=${lat},${lng}`;
    setMarker({ lat, lng });
    setMapLocation(newUrl); // Update mapLocation with the new URL
    fetchAddress(lat, lng); // Fetch the new address
  };

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div>
       <div className="d-flex align-items-center mb-3 gap-2" >
       <FaArrowLeft color="black"  onClick={() => setIsMapScreen(false)} /> <span>Create Location</span>  <IoIosArrowForward color="black"  size={"14px"} style={{marginTop:"2px"}}/> <span style={{color:"#646569"}}>Add from Map</span>
       </div>
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={{ width: "100%", height: "400px" }}
            center={marker || centerPoint}
            zoom={10}
            onClick={handleMapClick}
          >
            {marker && <Marker position={marker} />}
          </GoogleMap>
        ) : (
          <p>Loading Map...</p>
        )}
        <div className={classes.addressmap_container}>
          <h5>{locality ? locality : "n/a"}</h5>
          <p>{address ? address : "n/a"}</p>
          <Button
            variant="contained"
            onClick={() => setIsMapScreen(false)}
            disabled={!marker}
            className={classes.addressmap_button}
          >
            Add Location
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default AddressMapLocation;
