import React, { useEffect, useState } from "react";
import { Badge, Paper, Avatar, Chip, Typography } from "@mui/material";
import classes from "./myteam.module.css";
import pdf from "../../../assets/images/Group 772547013.png";
import {
  checkOnline,
  formatBytes,
  generateColor,
  stringAvatar,
} from "../../../utilities/commonFuntions";
import { RiDeleteBin6Line } from "react-icons/ri";
import axios from "axios";
import {
  DELETE_DOCUMENT,
  ImageUrl,
  TEAM_MEMBER_DETAILS,
} from "../../../api-calls/api-urls";
import {
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import { useDispatch, useSelector } from "react-redux";
import { TeamMemberData } from "../../../Redux/actions/team-actions";
import { getLanguagedataFunction } from "../../../utilities/language";
import { CallIcon, DeleteBinIcons } from "../../../components/Icons";
import { useNavigate } from "react-router-dom";
const TeamMemberDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOnline, setIsOnline] = useState(false);
  const { teamMemberData } = useSelector((state) => state.reducers.teams);
  const ln = useSelector((state) => state.reducers.allUsers.language_json);
  const { teamMembersList } = useSelector((state) => state.reducers.allUsers);
  const [deleteDocument, setDeleteDocumnt] = useState([]);
  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  const getTeamMemberData = async () => {
    const path = window.location.pathname.split("/");
    const { data } = await authApi.post(
      `${TEAM_MEMBER_DETAILS}/${path[path.length - 1]}`,
      {},
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );
    dispatch(TeamMemberData(data));
  };

  useEffect(() => {
    console.log(teamMemberData);
  }, [teamMemberData]);

  useEffect(() => {
    getTeamMemberData();
  }, [!teamMemberData,deleteDocument]);
  const handlePdfClick = async (item) => {
    console.log(item);
    const url = `${process.env.REACT_APP_IMAGE_URL}/${item.path}${item.filename}`;

    try {
      const response = await axios.get(url, {
        responseType: "blob",
      });

      const contentType = response.headers["content-type"];

      // If the file is a PDF, open it in a new window
      if (contentType === "application/pdf") {
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
      }
      // If the file is an image, download it
      else if (contentType.startsWith("image/")) {
        const file = new Blob([response.data], { type: contentType });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = fileURL;
        // Use `originalName` for the download filename to preserve extension
        link.download = item.originalName; // Set the download attribute to the full filename (e.g., "images.jpeg")
        link.click();
      }
      // For other file types, download the file with the correct extension
      else {
        const file = new Blob([response.data], { type: contentType });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement("a");
        // Use `originalName` for download in case of other file types
        link.href = fileURL;
        link.download = item.originalName; // Use the full filename with extension (e.g., "somefile.pdf")
        link.click();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (teamMemberData) {
      console.log(teamMemberData?.user_id?._id);
      console.log(checkOnline(teamMemberData?.user_id?._id));
    }
  }, [teamMemberData]);

  const getFileTypeImage = (name) => {
    if (name.includes("pdf")) {
      return pdf;
    }
  };
  function truncateFileName(fileName) {
    // Find the index of the file extension
    const extensionIndex = fileName.lastIndexOf(".");
    const extension = fileName.substring(extensionIndex); // Get the file extension

    // Extract the main name (excluding the extension)
    const namePart = fileName.substring(0, extensionIndex);

    // Truncate the name if it's longer than 10 characters
    if (namePart.length > 10) {
      return namePart.substring(0, 10) + extension;
    }

    // Return the original file name if the length is 10 or less
    return fileName;
  }
  const handleDelete = async (data) => {
    try {
      console.log(data);

      const deleteDocu = await authApi.delete(
        `${DELETE_DOCUMENT}/${data.user_id}/${data._id}`,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );
      setDeleteDocumnt(deleteDocu);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      {ln ? (
        <div className={classes.detailspageMaindiv}>
          <Paper className={classes.TeamMemberDetailsPage1}>
            <div className={classes.locationGridCardHeader}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "10px",
                  width: "70%",
                }}
              >
                <Badge
                  color={
                    checkOnline(
                      teamMembersList,
                      teamMemberData?.user_id?._id
                    ) === "checkin"
                      ? "success"
                      : "error"
                  }
                  badgeContent=" "
                  variant="dot"
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  className={classes.LocationOnlineBadge}
                >
                  {teamMemberData &&
                  teamMemberData?.user_id &&
                  teamMemberData?.user_id?.profile_pic &&
                  teamMemberData?.user_id?.profile_pic?.filename ? (
                    <Avatar
                      alt={teamMemberData.name}
                      src={`${process.env.REACT_APP_IMAGE_URL}/${teamMemberData?.user_id?.profile_pic?.destination}${teamMemberData?.user_id?.profile_pic?.filename}`}
                      sx={{ width: 40, height: 40 }}
                    />
                  ) : (
                    <Avatar
                      {...stringAvatar(teamMemberData.name)}
                      sx={{
                        bgcolor: `${generateColor(teamMemberData.name)}`,
                      }}
                    />
                  )}
                </Badge>
                <div className={classes.memberCardText}>
                  <Typography className={classes.form_lable}>
                    {ln.team_member}
                  </Typography>
                  <Typography className={classes.CardValues}>
                    {teamMemberData.name}
                  </Typography>
                </div>
              </div>
              <div className={classes.memberCardText}>
                <Typography className={classes.form_lable}>
                  {ln.team}
                </Typography>
                <Typography className={classes.CardValues}>
                  {teamMemberData.team.team_name}
                </Typography>
              </div>
              <div className={classes.memberCardText}>
                <Typography className={classes.form_lable}>
                  {ln.mobile_number}
                </Typography>
                <Typography
                  className={`${classes.CardValues} ${classes.phoneIcom}`}
                >
                  {`+91 ${teamMemberData.phone}`}{" "}
                  <span className={classes.form_lable}>|</span> <CallIcon />
                </Typography>
              </div>
              <div className={classes.memberCardText}>
                <Typography className={classes.form_lable}>
                  {ln.department}
                </Typography>
                <Typography className={classes.CardValues}>
                  {teamMemberData.department.department_name}
                </Typography>
              </div>
            </div>
          </Paper>
          <Paper
            className={`${classes.TeamMemberDetailsPage1} ${classes.memberCardText}`}
          >
            <Typography>{ln.documents}</Typography>
            {teamMemberData && teamMemberData.documents.length > 0 ? (
              <div className={classes.documentContainer}>
                {teamMemberData.documents.map((item, index) => {
                  return (
                    <Paper key={index} className={classes.documentCard}>
                      <img
                        src={getFileTypeImage(item.mimetype)}
                        className={classes.extensionImage}
                        width={50}
                        height={50}
                        alt="extension"
                        onClick={() => handlePdfClick(item)}
                      />
                      <Typography
                        className={`${classes.detailsname} ${classes.documentName}`}
                      >
                        {truncateFileName(item.originalName)}
                      </Typography>
                      <Typography className={classes.form_lable}>
                        {formatBytes(item.size)}
                      </Typography>
                      <Typography className={classes.detailsname}>
                        <span onClick={() => handleDelete(item)}>
                          <DeleteBinIcons/>
                        </span>
                      </Typography>
                    </Paper>
                  );
                })}
              </div>
            ) : (
              "no team members found"
            )}
          </Paper>
        </div>
      ) : null}
    </div>
  );
};

export default TeamMemberDetails;
