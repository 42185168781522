import { Box, Dialog, Typography, Button, Grid, MenuItem } from "@mui/material";
import "./address.css";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import classes from "./address.module.css";
import { Numeric } from "../../../utilities/commonFuntions";
import { useEffect, useState } from "react";
import { AddAddressCloseIcon } from "../../../components/Icons";
import { useDispatch, useSelector } from "react-redux";

function AddAddressPop(props) {
  const {
    open,
    handleClose,
    inputs,
    setInputs,
    handleInputs,
    setIsMapScreen,
    handleAddAddress,
    singleAddress,
    handleUpdateAddress,
  } = props;

  const { addSuccess, addFailure } = useSelector(
    (state) => state.reducers.address
  );
  const dispatch = useDispatch();

  useEffect(() => {
    // Adding custom validation rule for phone number length
    ValidatorForm.addValidationRule("isTenDigits", (value) => {
      if (value && value.length !== 10) {
        return false;
      }
      return true;
    });

    // Clean up the rule on unmount
    return () => ValidatorForm.removeValidationRule("isTenDigits");
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="add-address-pop"
      >
        <Box>
          <Box className={classes.add_header}>
            <Typography
              fontSize={"23px"}
              fontWeight={"600"}
              sx={{ color: "var(--border-colour)" }}
            >
              {singleAddress ? "Edit" : "Add"} Address
            </Typography>
            <span onClick={handleClose} className={classes.address_close_icon}>
              <AddAddressCloseIcon />
            </span>
          </Box>
          <Box p={3} pt={1}>
            <ValidatorForm
              useref="form"
              // onSubmit={handleAddService}
              className={classes.SignUpForm}
            >
              <Box mb={2}>
                <Typography className={classes.add_address_tit}>
                  Name
                </Typography>
                <TextValidator
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  id="emailInput"
                  placeholder="eg: arun"
                  className={`${classes.formInputFeild} my-field`}
                  name="name"
                  value={inputs.name}
                  size="normal"
                  onChange={handleInputs}
                  // onKeyPress={(e) => {
                  //   Numeric(e);
                  // }}
                  validators={["required"]}
                  errorMessages={["Name is required"]}
                />
              </Box>
              <Box mb={2}>
                <Typography className={classes.add_address_tit}>
                  Mobile Number (optional)
                </Typography>
                <TextValidator
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  id="phoneInput"
                  placeholder="eg: 8615222330"
                  className={`${classes.formInputFeild} my-field`}
                  name="phone"
                  value={inputs.phone}
                  size="normal"
                  onChange={handleInputs}
                  onKeyPress={(e) => {
                    Numeric(e);
                  }}
                  validators={["isTenDigits"]}
                  errorMessages={["Mobile number must be exactly 10 digits"]}
                />
              </Box>
              <Box mb={2}>
                <Typography className={classes.add_address_tit}>
                  Address (optional)
                </Typography>
                <TextValidator
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  id="emailInput"
                  placeholder="eg: 123 street"
                  className={`${classes.formInputFeild} my-field`}
                  name="address"
                  value={inputs.address}
                  size="normal"
                  onChange={handleInputs}
                />
              </Box>
              <Box mb={2}>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography className={classes.add_address_tit}>
                    Google Map Location
                  </Typography>
                  <Button
                    className={classes.add_from_map}
                    size="small"
                    onClick={() => setIsMapScreen(true)}
                  >
                    Add from Map
                  </Button>
                </Box>

                <TextValidator
                  variant="outlined"
                  multiline
                  rows={2}
                  autoComplete="off"
                  fullWidth
                  id="emailInput"
                  placeholder=""
                  className={`${classes.formInputFeild}`}
                  name="maplink"
                  value={inputs.maplink}
                  size="normal"
                  onChange={handleInputs}
                  validators={["required"]}
                  errorMessages={["Google map link required"]}
                />
              </Box>
              <Box marginTop="20px">
                {singleAddress ? (
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.add_address}
                    type="submit"
                    onClick={handleUpdateAddress}
                  >
                    Update Address
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.add_address}
                    type="submit"
                    onClick={handleAddAddress}
                  >
                    Add Address
                  </Button>
                )}
              </Box>
              {addSuccess && (
                <Typography className={classes.success_text}>
                  Successfully {singleAddress ? "Updated" : "Added"}{" "}
                </Typography>
              )}
              {addFailure && (
                <Typography className={classes.failure_text}>
                  Try Again later{" "}
                </Typography>
              )}
            </ValidatorForm>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

export default AddAddressPop;
