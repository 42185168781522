import { Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import classes from "./location.module.css";
import { useDropzone } from "react-dropzone";
import { formatBytes } from "../../../utilities/commonFuntions";
import {
  authApi,
  authImageApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import Dropzone from "react-dropzone";
import { ImageUrl, UPLOAD_IMAGE } from "../../../api-calls/api-urls";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { saveImageList } from "../../../Redux/actions/location-action";
import { getLanguagedataFunction } from "../../../utilities/language";
import { DeleteBinIcons } from "../../../components/Icons";

const Basic = ({ images, onDropFunction }) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [currentFiles, setcurrentFiles] = useState([]);
  const imageList = useSelector((state) => state.reducers.location.imageList);
  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  const onDrop = (acceptedFiles) => {
    try {
      const newArray = [...files, ...acceptedFiles];
      setFiles(newArray);
      onDropFunction(newArray);
    } catch (error) {
      console.error("Error updating state:", error);
    }
  };

  useEffect(() => {
    if (images && images.length > 0) {
      setFiles([...images]);
    }
  }, [images]);

  const remove = (filename) => {
    try {
      const newList = files.filter(
        (item) => item.filename !== filename && item.name !== filename
      );
      setFiles([...newList]);
      onDropFunction(newList);
    } catch (error) {
      console.error("Error updating state:", error);
    }
  };
  const filenameConvert = (filename) => {
    const spliteName = filename.split(".");
    console.log(spliteName);

    if (spliteName[0].length > 10) {
      filename = filename.substring(0, 10);
      return filename + "." + spliteName[spliteName.length - 1];
    }
    return filename;
  };
  return (
    <div>
      {ln ? (
        <div className={classes.DropZoneContainer}>
          <Dropzone onDrop={onDrop}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ className: classes.DropZone })}>
                <input {...getInputProps()} />
                <Button variant="contained" className={classes.DropzoneBtn}>
                  {ln.browse}
                </Button>
                <div>{ln.drop_here}</div>
              </div>
            )}
          </Dropzone>
          {window.location.pathname.includes("update") &&
          files &&
          files.length > 0 ? (
            <div className={classes.imageContainer}>
              {files.map((item) => {
                const image =
                  item?.filename && item?.destination
                    ? `${process.env.REACT_APP_IMAGE_URL}/${item.destination}${item.filename}`
                    : URL.createObjectURL(item);
                return (
                  <div
                    className={classes.subImageContainer}
                    key={item.filename}
                  >
                    <img
                      src={image}
                      width="250px"
                      height="150px"
                      style={{ maxHeight: "250px" }}
                      alt={item.originalname || item.name}
                    />
                    <div className={classes.imageText}>
                      <Tooltip title={item.originalname || item.name}>
                        <Typography
                          className={`${classes.form_lable} ${classes.detailsText1}`}
                          noWrap
                          style={{
                            overflow: "hidden",
                            width: "150px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {filenameConvert(item.originalname || item.name)}
                          {/* {item.originalname || item.name} */}
                        </Typography>
                      </Tooltip>

                      <div style={{ display: "flex", columnGap: "10px" }}>
                        {item.size ? (
                          <Typography className={`${classes.form_lable_size }` }>
                            {formatBytes(item.size)}
                          </Typography>
                        ) : null}
                        <Typography
                          className={`${classes.form_lable} ${classes.ImageDeleteBtn}`}
                          onClick={() => remove(item.filename)}
                        >
                          <DeleteBinIcons />
                        </Typography>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
          {window.location.pathname.includes("create") &&
          files &&
          files.length > 0 ? (
            <div className={classes.imageContainer}>
              {files.map((item, index) => {
                const image = URL.createObjectURL(item);
                return (
                  <div className={classes.subImageContainer} key={index}>
                    <img
                      src={image}
                      width="250px"
                      height="150px"
                      alt={item.name}
                    />
                    <div className={classes.imageText}>
                      <Tooltip title={item.name}>
                        <Typography
                          className={`${classes.form_lable} ${classes.detailsText1}`}
                          noWrap
                          style={{
                            overflow: "hidden",
                            width: "150px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {item.name}
                        </Typography>
                      </Tooltip>

                      <div
                        style={{
                          display: "flex",
                          columnGap: "10px",
                          alignItems: "center",
                          marginTop: "4px",
                        }}
                      >
                        {item.size ? (
                          <Typography
                            className={`${classes.form_lable}`}
                            style={{
                              fontSize: "13px",
                            }}
                          >
                            {formatBytes(item.size)}
                          </Typography>
                        ) : null}
                        <Typography
                          className={`${classes.form_lable} ${classes.ImageDeleteBtn}`}
                          onClick={() => remove(item.name)}
                        >
                          <DeleteBinIcons />
                        </Typography>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>

    // <section>
    //   <div {...getRootProps({ className: classes.DropZone })}>
    //     <input
    //       {...getInputProps({
    //         onChange: handleFile,
    //       })}
    //     />
    //     <Button variant="contained" className={classes.DropzoneBtn}>
    //       Browse
    //     </Button>
    //     <div>Or drop file here</div>
    //   </div>
    //   <aside>{files}</aside>
    // </section>
  );
};

export default Basic;
