import React, { useState, useRef, useEffect } from "react";
import classes from "./location.module.css";
import { IconButton, Card, CardContent, Typography } from "@mui/material";
import { RiMicFill, RiStopCircleFill } from "react-icons/ri";
import { Button } from "@mui/material";
import { BsTrash } from "react-icons/bs";
import Waveform from "./audioPlayer";
import {
  authApi,
  authImageApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import {
  DELETE_VOICE_FILE,
  ImageUrl,
  LOCATION,
  UPLOAD_IMAGE,
} from "../../../api-calls/api-urls";
import { useDispatch } from "react-redux";
import { SaveAudio } from "../../../Redux/actions/location-action";
import { AudioRecordMicIcon } from "../../../components/Icons";
import AlertBox from "../../../components/AlertBox";

const Recorder = (props) => {
  console.log(props);
  // setEditLocation
  const dispatch = useDispatch();
  const [recording, setRecording] = useState(false);
  const [audioURL, setAudioURL] = useState("");
  const [audioBlob, setAudioBlob] = useState(null);
  const [duration, setDuration] = useState(0);
  const [audioPlayer, setAudioPlayer] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState("");
  const mediaRecorder = useRef(null);

  const startRecording = () => {
    if (
      !window.location.pathname.includes("create") &&
      !window.location.pathname.includes("update")
    ) {
      setShowAlert(true);
      setError("Please Go to the Edit Page to upload or delete audio.");
      return;
    }
    const constraints = { audio: true };
    navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
      mediaRecorder.current = new MediaRecorder(stream);
      const audioChunks = [];

      mediaRecorder.current.addEventListener("dataavailable", (event) => {
        audioChunks.push(event.data);
      });

      mediaRecorder.current.addEventListener("stop", () => {
        const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
        const audioURL = URL.createObjectURL(audioBlob);

        setAudioBlob(audioBlob);
        setAudioURL(audioURL);
        audioChunks.length = 0;
      });

      mediaRecorder.current.start();
      setRecording(true);
    });
  };

  const stopRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state !== "inactive") {
      mediaRecorder.current.stop();
      setRecording(false);
    }
  };

  const getAudioBlobDuration = async (audioBlob) => {
    try {
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      const arrayBuffer = await audioBlob.arrayBuffer();

      const audioBuffer = await new Promise((resolve, reject) => {
        audioContext.decodeAudioData(arrayBuffer, resolve, reject);
      });

      const durationInSeconds = audioBuffer.duration;
      return durationInSeconds;
    } catch (error) {
      console.error("Error getting audio blob duration:", error);
      return 0;
    }
  };
  const getOneLocationdata = async (path) => {
    const arr = path.split("/");
    const { data } = await authApi.get(`${LOCATION}/${arr[arr.length - 1]}`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    props.setEditLocation(data.data);
  };
  useEffect(() => {
    if (audioBlob && audioURL) {
      CreateimageArray();
      const duration = getAudioBlobDuration(audioBlob);
    }
  }, [audioBlob && audioURL]);

  const CreateimageArray = async (items) => {
    console.log("AudioBlob", audioBlob);
    console.log("AudioURL", audioURL);

    let formData = new FormData();
    formData.append("file", audioBlob);
    const { data } = await authImageApi.post(UPLOAD_IMAGE, formData, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    const audiofilepath = data.data;
    dispatch(SaveAudio(audiofilepath));
  };

  const playAudio = () => {
    if (audioBlob) {
      if (audioPlayer) {
        audioPlayer.pause();
        audioPlayer.currentTime = 0;
      }

      const audio = new Audio(audioURL);
      audio.play();
      setAudioPlayer(audio);
    }
  };

  const pauseAudio = () => {
    if (audioPlayer) {
      audioPlayer.pause();
    }
  };

  const deleteAudio = async() => {
    if (
      !window.location.pathname.includes("create") &&
      !window.location.pathname.includes("update")
    ) {
      setShowAlert(true);
      setError("Please Go to the Edit Page to upload or delete audio.");
      return;
    }
    if (audioPlayer) {
      audioPlayer.pause();
      audioPlayer.currentTime = 0;
      setAudioPlayer(null);
    }
    if (props.audio.audio_note) {
    await  DeleteAudioFunction();
    getOneLocationdata(window.location.pathname);
    }
    setAudioURL("");
    console.log(audioURL);
    props.setAudioFile1(null);
    setAudioBlob(null);
  };

  const triggerAlertBox = () => {
    setError("Please Go to the Edit Page to upload or delete audio.");
    setShowAlert(true);
  };
  const DeleteAudioFunction = async () => {
    console.log(props);
  
    try {
      const { data } = await authApi.delete(
        `${DELETE_VOICE_FILE}/${props.audio._id}`,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );
      console.log("Delete successful:", data);
    } catch (error) {
      console.error("Error deleting audio file:", error);
    }
  };
  
  return (
    <Card
      sx={{ height: "max-content", width: audioURL ? "max-content" : "345px" }}
      className={classes.locationsmainDiv}
    >
      <CardContent sx={{ width: "100%", paddingBottom: "16px !important" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography className={classes.AudioRecorderHeading}>
            Add an audio note{" "}
            <span style={{ color: "#0A1F41" }}>(Optional)</span>
          </Typography>
          <IconButton
            disabled={audioURL ? true : false}
            className={audioURL ? classes.AudioRecorderBtn : ""}
            onClick={recording ? stopRecording : startRecording}
          >
            {recording ? <RiStopCircleFill /> : <AudioRecordMicIcon />}
          </IconButton>
        </div>
        {audioURL && (
          <div className={classes.audioPlayer}>
            <Waveform audio={audioURL} />
            <Button
              onClick={() => deleteAudio()}
              className={classes.AudioPlayerBtn}
            >
              <BsTrash />
            </Button>
          </div>
        )}
        {props.audio &&
          props.audio.audio_note &&
          props.audio.audio_note.filename && (
            <div className={classes.audioPlayer}>
              <Waveform
                audio={`${process.env.REACT_APP_IMAGE_URL}/${props.audio.audio_note.destination}${props.audio.audio_note.filename}`}
              />
              <Button
                onClick={() => deleteAudio()}
                className={classes.AudioPlayerBtn}
              >
                <BsTrash />
              </Button>
            </div>
          )}
      </CardContent>
      {showAlert && error && (
        <AlertBox type="error" message={error} stateName={setShowAlert} />
      )}
    </Card>
  );
};

export default Recorder;
