import React, { useEffect, useState } from "react";
import { Outlet, Routes, useLocation, useNavigate } from "react-router-dom";
import classes from "./index.module.css";
import { Navigate } from "react-router-dom";
import SideNavBar from "./Sidebar";
import Header from "./Header";
// use redux provider to get the state
import { useDispatch, useSelector } from "react-redux";
import { initializeXmppClient, setPresenceOnline } from "../utilities/Xmpp";
import Cookies from "js-cookie";
import { authApi, getAuthorizationHeader } from "../api-calls/user-requests";
import { GET_USER, TEAM_MEMBERS_LIST } from "../api-calls/api-urls";
import { getMqttData } from "../utilities/commonFuntions";
import store from "../Redux/store";

const DefaultLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [loggedUser, setLoggedUser] = useState();
  const [membersList, setMembersList] = useState([]);
  const { togglesidebar, teamMembersList } = useSelector(
    (state) => state.reducers.allUsers
  );
  const currentUser = JSON.parse(sessionStorage.getItem("userInfo"));
  const token = Cookies.get("userToken");
  const getCurrentLoggedUser = async () => {
    try {
      const { data } = await authApi.get(`${GET_USER}/${currentUser?._id}`, {
        headers: { Authorization: getAuthorizationHeader() },
      });
      console.log(data);

      setLoggedUser(data);
    } catch (error) {
      console.log(error);
    }
  };
  console.log(loggedUser);

  const getTeamMembersData = async () => {
    try {
      const { data } = await authApi.get(
        `${TEAM_MEMBERS_LIST}/${loggedUser?.company}`,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );
      if (data.length > 0) {
        setMembersList(data);
        dispatch({ type: "TEAM_MEMBERS_LIST", payload: data });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (loggedUser && teamMembersList.length === 0) {
      getTeamMembersData();
    }
  }, [loggedUser, teamMembersList]);

  useEffect(() => {
    getCurrentLoggedUser();
  }, []);

  useEffect(() => {
    // console.log();
  }, [teamMembersList]);

  // const changeStatus = async (message) => {
  //   // console.log(message);
  //   let updatedTeamMembersList;
  //   if (message) {
  //     const findMember = await teamMembersList.find(
  //       (member) => member.user_id._id === message.user_id
  //     );

  //     console.log("tEAMmEMBER", teamMembersList);

  //     if (findMember) {
  //       updatedTeamMembersList = await teamMembersList.map((member) => {
  //         if (member.user_id._id === findMember.user_id._id) {
  //           return {
  //             ...member,
  //             status: message.status,
  //           };
  //         }
  //         return member;
  //       });
  //       console.log(updatedTeamMembersList);
  //     }
  //     dispatch({
  //       type: "TEAM_MEMBERS_LIST",
  //       payload: updatedTeamMembersList,
  //     });
  //   }
  // };

  useEffect(() => {
    if (
      membersList &&
      membersList.length > 0 &&
      !window.location.pathname.includes("map")
    ) {
      getMqttData(loggedUser?.company, membersList, dispatch, "status");
    }
  }, [membersList]);

  useEffect(() => {
    if (currentUser) {
      initializeXmppClient(currentUser, dispatch);
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (token) {
      sessionStorage.setItem("userInfo", token);
    }
    if (!currentUser && !token) {
      navigate("/login");
    }
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Header />
      </div>
      <div className={classes.mainLayout}>
        <div className={togglesidebar ? classes.sidebar : classes.smallSidebar}>
          <SideNavBar />
        </div>
        <div className={classes.content}>
          <div className={classes.scrollableOutlet}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;
