import React, { useEffect } from "react";
import { Badge, Avatar, Typography } from "@mui/material";
import classes from "./chat.module.css";
import {
  checkOnline,
  generateColor,
  stringAvatar,
} from "../../../utilities/commonFuntions";
import { useSelector } from "react-redux";

const ChatContactCard = ({ index, OpenChat, member, ChatMembers }) => {
  const { teamMembersList } = useSelector((state) => state.reducers.allUsers);

  useEffect(() => {
    console.log(member, "Check ProfilePic");
  }, []);

  return (
    <div key={index} style={{ position: "relative" }}>
      <div
        className={classes.chat_IndividualContactsMainDiv}
        onClick={() => OpenChat(member)}
      >
        <div className={classes.chat_IndividualContactsSubDiv}>
          {member.conversation_type === "group" ? (
            <div>
              <Avatar
                {...stringAvatar(`${member?.name.trim()}`)}
                sx={{
                  bgcolor: `${generateColor(`${member?.name}`)}`,
                }}
              />
            </div>
          ) : (
            <Badge
              color={
                checkOnline(member?.receiverDetails[0]?._id) === "checkin"
                  ? "success"
                  : "error"
              }
              badgeContent=""
              variant="dot"
              overlap="circular"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              className={classes.LocationOnlineBadge}
            >
              {member?.receiverDetails[0]?.profile_pic ? (
                <Avatar
                  alt={member?.receiverDetails[0]?.name}
                  src={`${process.env.REACT_APP_IMAGE_URL}/${member?.receiverDetails[0]?.profile_pic?.destination}${member?.receiverDetails[0]?.profile_pic?.filename}`}
                />
              ) : (
                <Avatar
                  {...stringAvatar(
                    `${member?.receiverDetails[0]?.name.trim()}`
                  )}
                  sx={{
                    bgcolor: `${generateColor(
                      `${member?.receiverDetails[0]?.name}`
                    )}`,
                  }}
                />
              )}
            </Badge>
          )}
          <div>
            <Typography className={classes.chat_ContactPersonName}>
              {member?.name.split("-")[0]}
            </Typography>
            <Typography className={classes.Chat_lastMessage}>
              {member.lastmessage}
            </Typography>
          </div>
        </div>

        <div>
          {/* <Typography className={classes.Chat_lastMessage}>
            Yesterday
          </Typography> */}
          {member.unseenCount > 0 && (
            <Typography className={classes.messageCount}>
              {member.unseenCount}
            </Typography>
          )}
        </div>
      </div>
      {index + 1 !== ChatMembers.length ? (
        <hr className={classes.Chart_contactUnderLine} />
      ) : null}
    </div>
  );
};

export default ChatContactCard;
