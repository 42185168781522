import React, { useEffect, useState } from "react";
import ImageCarosel from "../../../components/ImageCarosel";
import classes from "../Registration/index.module.css";
import AppLogo from "../../../assets/images/mainLogo.png";
import { loggedUser, pageLoader } from "../../../Redux/actions/user-actions";
import { api } from "../../../api-calls/user-requests";
import { OTP_LOGIN } from "../../../api-calls/api-urls";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import "../../../assets/css/style.css";
import { LOGIN } from "../../../api-calls/api-urls";
import "../../../assets/css/bootstrap.min.css";
import { Button, Card, Typography, Snackbar, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ForgetPassword from "../ForgetPassword/ForgetPassword";
import { getLanguagedataFunction } from "../../../utilities/language";
import { CloseEyeIcon, OpenEyeIcon } from "../../../components/Icons";
import { setPresenceOnline } from "../../../utilities/Xmpp";
import Cookies from "js-cookie";
import AlertBox from "../../../components/AlertBox";

const EmailLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isNumber, setIsNumber] = useState(true);
  const [email, setEmail] = useState("");
  const [modalToggle, setModalToggle] = useState();
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const [mobile, setMobile] = useState();
  const [rememberMe, setRememberMe] = useState(false);
  const [maxlength, setMaxLength] = useState(10);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState("");
  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const closeForm = () => {
    setModalToggle(false);
  };

  useEffect(() => {
    console.log(rememberMe);
  }, [rememberMe]);

  useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  const handleSubmit = async () => {
    const userEntry = {
      email: email,
      phone: mobile,
      password: password,
    };
    try {
      dispatch(pageLoader(true));
      const { data } = await api.post(LOGIN, userEntry);
      if (data.user_type === "team_player") {
        setShowAlert(true);
        setError("Team Leader Only has a Access");
      } else {
        await sessionStorage.setItem("userInfo", JSON.stringify(data));
        dispatch(loggedUser(data));
        if (rememberMe && data && data.token) {
          // console.log("Datas OFfered", rememberMe, data);
          Cookies.set("userToken", JSON.stringify(data), { expires: 7 });
          // localStorage.setItem('userToken', JSON.stringify(data.token)); // Expires in 7 days
          sessionStorage.removeItem("rememberMe");
        }
        if (data) {
          await setPresenceOnline(data, dispatch);
        }
        navigate("/home");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setShowAlert(true);
        setError(error.response.data.message);
      } else {
        // Handle other errors
        // ...
      }
    }
  };

  const handleclick = (value) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (re.test(value)) {
      setIsNumber(false);
      setMaxLength(10);
      setMobile(value);
      setEmail(null);
    } else {
      setMaxLength(null);
      setIsNumber(true);
      setMobile(null);
      setEmail(value);
    }
  };
  const handleOtpLogin = async () => {
    const userEntry = {
      phone: mobile,
    };
    try {
      dispatch(pageLoader(true));
      const { data } = await api.post(OTP_LOGIN, userEntry);
      sessionStorage.setItem("mobile", JSON.stringify(mobile));
      sessionStorage.setItem("loginOtp", JSON.stringify(data));
      navigate("/otp_login");
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setShowAlert(true);
        setError(error.response.data.message);
      }
    }
  };

  useEffect(() => {
    if (showAlert) {
      const timeout = setTimeout(() => {
        setShowAlert(false);
      }, 3000); // Change the duration (in milliseconds) as per your requirement

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showAlert]);

  return (
    <>
    
    <div className={classes.maindivForlogin}>
      {ln ? (
        <Card className={classes.SelectLanguage_maindiv} style={{width:"100%",height:"100%"}}>
          <div>
            <ImageCarosel />
          </div>
          <ValidatorForm
            useref="form"
            onSubmit={handleSubmit}
            className={classes.InputForm}
          >
            <div className={`col-lg-12 ${classes.MaincntainerDiv}`}>
              <div className="p-lg-5 p-4">
                <div className="text-left">
                  <span className="logo-lg mb-5">
                    <img src={AppLogo} alt="" height={50} />
                  </span>
                  <Typography className={`mt-4 ${classes.loginHeading}`}>
                    {ln["login"]}
                  </Typography>
                  <Typography className={`mt-4 ${classes.loginDescription}`}>
                    {ln.login_with_email_and_password}
                  </Typography>
                </div>
                <div className="mt-4">
                  <div className="mb-3">
                    <label
                      htmlFor="username"
                      className={classes.login_form_lable}
                    >
                      {ln.mobile_number}/{ln.email_id}
                    </label>
                    <TextValidator
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      id="emailidInput"
                      fullWidth
                      value={email || mobile}
                      inputProps={{
                        maxLength: maxlength,
                      }}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        handleclick(e.target.value);
                      }}
                      size="small"
                      validators={["required"]}
                      errorMessages={["Email/Mobile Number is required"]}
                    />
                    <Button
                      disabled={isNumber}
                      variant="text"
                      sx={{
                        "&.Mui-disabled": {
                          color: "#0000ff73 !important",
                        },
                      }}
                      className={classes.useOtpBtn}
                      onClick={() => handleOtpLogin()}
                    >
                      {ln.use_otp_for_login}
                    </Button>
                  </div>
                  <div className="mb-2">
                    <label
                      htmlFor="userpassword"
                      className={classes.login_form_lable}
                    >
                      {ln.password}
                    </label>
                    <div className="position-relative auth-pass-inputgroup mb-3">
                      <div className={classes.passwordInputContainer}>
                        <TextValidator
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                          }}
                          id="outlined-adornment-password"
                          type={showPassword ? "text" : "password"}
                          fullWidth
                          size="small"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          validators={["required"]}
                          errorMessages={["Password is required"]}
                        />
                        <div className={classes.showPassword}>
                          {showPassword ? (
                            <Button
                              variant="text"
                              onClick={() => setShowPassword(false)}
                            >
                              <OpenEyeIcon />
                            </Button>
                          ) : (
                            <Button onClick={() => setShowPassword(true)}>
                              <CloseEyeIcon />
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`form-check form-check-primary fs-16 py-2 ${classes.CheckboxContainer}`}
                    style={{ display: "flex" }}
                  >
                    <div>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="remember-check"
                        onClick={() => {
                          setRememberMe(!rememberMe);
                          sessionStorage.setItem("rememberMe", !rememberMe);
                        }}
                      />
                      <label
                        className={`${classes.login_form_lable} ${classes.remember_input}`}
                        htmlFor="remember-check"
                      >
                        {ln.remember_me}
                      </label>
                    </div>
                    <div>
                      <Button
                        variant="text"
                        className={`${classes.login_form_lable} ${classes.remember_input} ${classes.forgotbtn}`}
                        style={{ textTransform: "capitalize" }}
                        onClick={() => setModalToggle(true)}
                      >
                        {ln.forgot_password}?
                      </Button>
                    </div>
                  </div>
                  <div className="mt-2">
                    <Button
                      variant="contained"
                      className={`${classes.commonbtn}`}
                      type="submit"
                    >
                      {ln.login}
                    </Button>
                  </div>
                  <div className="mt-2 text-center">
                    <Typography
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <span className={classes.loginDescription}>
                        {ln.dont_have_an_account}
                      </span>
                      <Button
                        variant="text"
                        className={classes.registerBtn}
                        onClick={() => navigate("/signup")}
                        style={{ textTransform: "capitalize" }}
                      >
                        {ln.register}
                      </Button>
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </ValidatorForm>
        </Card>
      ) : null}
      {modalToggle ? <ForgetPassword modalClose={closeForm} /> : null}
  
    </div>
    <div style={{position:"absolute"}}>
    {showAlert && error && (
        <AlertBox type="error" message={error} stateName={setShowAlert} />
      )}
    </div>
    </>
  );
};

export default EmailLogin;
