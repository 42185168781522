import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import {
  Paper,
  Typography,
  Avatar,
  Button,
  Menu,
  MenuItem,
  Checkbox,
  Card,
  CardContent,
  Badge,
  TextField,
  Chip,
} from "@mui/material";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { FaList } from "react-icons/fa";
import { Form } from "react-bootstrap";
import classes from "./myteam.module.css";
import {
  locationStatusFilter,
  locationTimeFilter,
  range,
} from "../../../utilities/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  saveLocationStatusFilter,
  saveLocationTimeFilter,
} from "../../../Redux/actions/location-action";
import {
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import { LOCATION, TEAM_MEMBER_DETAILS } from "../../../api-calls/api-urls";
import {
  stringAvatar,
  generateColor,
  getChipColor,
} from "../../../utilities/commonFuntions";
import moment from "moment";
import MyTableBody from "../../../components/LocationListView";
import { useNavigate } from "react-router-dom";
import { TeamMemberData } from "../../../Redux/actions/team-actions";
import { getLanguagedataFunction } from "../../../utilities/language";
import {
  ArrowDownIcon,
  DatePickerCalendarIcon,
  FilterIcon,
  SortIcons,
} from "../../../components/Icons";

const TeamMemberLocaions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [date, setDate] = React.useState(null);
  const [anchorEl, setAnchorEl] = useState(null); // State to anchor the menu
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [selectedDate, setSelectedDate] = useState();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const open = Boolean(anchorEl);
  const open1 = Boolean(anchorEl1); // State to anchor the menu
  const [selectedValues, setSelectedValues] = useState(locationStatusFilter); // State to store selected values
  const [isOnline, setIsOnline] = useState(false);
  const [selectedValues1, setSelectedValues1] = useState("newest_to_oldest");
  const [activeButton, setActiveButton] = useState("grid");
  const { locationFilteredStatus, locationFilterTime } = useSelector(
    (state) => state.reducers.location
  );
  const { teamMemberData } = useSelector((state) => state.reducers.teams);
  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  const getTeamMemberData = async (userEntry) => {
    const path = window.location.pathname.split("/");
    if (fromDate !== undefined && toDate !== undefined) {
      const { data } = await authApi.post(
        `${TEAM_MEMBER_DETAILS}/${path[path.length - 1]}?start_date=${moment(
          fromDate
        ).format("YYYY-MM-DD")}&end_date=${moment(toDate).format(
          "YYYY-MM-DD"
        )}&view_all=${true}`,
        userEntry,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );
      dispatch(TeamMemberData(data));
    } else {
      const { data } = await authApi.post(
        `${TEAM_MEMBER_DETAILS}/${path[path.length - 1]}?&view_all=${true}`,
        userEntry,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );
      dispatch(TeamMemberData(data));
    }
  };

  useEffect(() => {
    console.log(teamMemberData);
  }, [teamMemberData]);

  useEffect(() => {
    const filterArray = {
      status: locationFilteredStatus,
      sortby: locationFilterTime,
    };
    getTeamMemberData(filterArray);
  }, [!teamMemberData, locationFilteredStatus, locationFilterTime]);

  const location_datas = {
    total: 20,
    completed: 10,
    rejected: 7,
    pending: 3,
  };

  useEffect(() => {
    if (fromDate !== undefined && toDate !== undefined) {
      setSelectedDate(
        `${moment(fromDate).format("DD-MM-YYYY")} to ${moment(toDate).format(
          "DD-MM-YYYY"
        )}`
      );
    }
    getTeamMemberData();
  }, [fromDate, toDate]);

  const handleMenuclick = (event, name) => {
    console.log(event.currentTarget);
    name(event.currentTarget);
  };
  const clickClearAll = (name, value) => {
    if (value === "array") {
      name([]);
    } else {
      name();
    }
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    setAnchorEl1(null);
  };

  const handleCardClick = (e, item) => {
    const name = item.Location.slug.toLowerCase();
    navigate(`/location/${name}`);
    e.preventDefault();
  };

  const handleClick = (event, name) => {
    if (name === "ref1") {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl1(event.currentTarget);
    }
  };

  const handleStatusCheckbox = (value) => {
    const isSelected = selectedValues.includes(value);

    if (value === "all") {
      if (isSelected) {
        // Unselect all checkboxes
        setSelectedValues([]);
        dispatch(saveLocationStatusFilter([]));
      } else {
        // Select all checkboxes
        setSelectedValues([...locationStatusFilter]);
        dispatch(saveLocationStatusFilter([...locationStatusFilter]));
      }
    } else {
      // Toggle the selection of individual checkboxes
      let newSelectedValues = [];

      if (isSelected) {
        newSelectedValues = selectedValues.filter((v) => v !== value);
      } else {
        newSelectedValues = [...selectedValues, value];
      }
      setSelectedValues(newSelectedValues);
      dispatch(saveLocationStatusFilter([newSelectedValues]));

      const areAllSelected = locationStatusFilter
        .filter((item) => item !== "all")
        .every((item) => newSelectedValues.includes(item));

      // Check the "All" checkbox if all others are selected
      if (areAllSelected) {
        setSelectedValues((prevSelected) => [...prevSelected, "all"]);
        dispatch(saveLocationStatusFilter([...newSelectedValues, "all"]));
      } else {
        // Uncheck the "All" checkbox if any other checkbox is unselected
        setSelectedValues((prevSelected) =>
          prevSelected.filter((item) => item !== "all")
        );
        dispatch(
          saveLocationStatusFilter(
            newSelectedValues.filter((item) => item !== "all")
          )
        );
      }
    }
  };
  const handleTimeCheckBox = (value) => {
    setSelectedValues1(value);
    dispatch(saveLocationTimeFilter(value));
  };

  const handleEvent = (event, picker) => {
    console.log("start: ", picker.startDate._d);
    console.log("end: ", picker.endDate._d);
    setFromDate(picker.startDate._d.toISOString());
    setToDate(picker.endDate._d.toISOString());
  };

  return (
    <div>
      {ln ? (
        <div className={classes.TeamMemberLocationPageMaindiv}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div className={classes.datePicker}>
              <DateRangePicker
                initialSettings={{ ranges: range }}
                alwaysShowCalendars={true}
                onApply={handleEvent}
              >
                <div className="d-flex align-items-center ">
                  <TextField
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                       width:"245px"
                    }}
                    size="small"
                    placeholder="Today"
                    value={selectedDate}
                  />
                  <span
                    className={classes.datePickerCalendarIcon}
                    style={{ right: "10px",top:"6px" }}
                  >
                    <DatePickerCalendarIcon />
                  </span>
                </div>
              </DateRangePicker>
            </div>
          </div>
          {teamMemberData ? (
            <div>
              <Paper className={classes.TeamMemberPiechartBox}>
                <Typography className={classes.teamPageHeading}>
                  {ln.total_locations}
                </Typography>
                <div className={classes.PieChartContainer}>
                  <div style={{ width: "150px", height: "150px" }}>
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 44 44"
                      className={classes.piechartSvg}
                    >
                      <circle
                        cx="50%"
                        cy="50%"
                        fill="transparent"
                        r="15.909091"
                        stroke="#eee"
                        strokeWidth={10}
                      />
                      <circle
                        show-tip="ctDonutTip"
                        className="ct-donut-slice"
                        cx="50%"
                        cy="50%"
                        fill="transparent"
                        r="15.909091"
                        stroke="#1273B8" // Completed color
                        strokeDasharray={`${
                          (teamMemberData.locationDetails.completed /
                            teamMemberData.locationDetails.total) *
                          100
                        } ${
                          (teamMemberData.locationDetails.total -
                            teamMemberData.locationDetails.completed /
                              teamMemberData.locationDetails.total) *
                          100
                        }`}
                        strokeWidth={10}
                        strokeDashoffset={0}
                        slice-title="Completed"
                        percent-value={11}
                        usage={11}
                        total-val={20}
                      >
                        <animate
                          attributeName="stroke-dashoffset"
                          from={100}
                          to={0}
                          dur="0.5s"
                        />
                      </circle>
                      <circle
                        show-tip="ctDonutTip"
                        className="ct-donut-slice"
                        cx="50%"
                        cy="50%"
                        fill="transparent"
                        r="15.909091"
                        stroke="#240051" // Pending color
                        strokeDasharray={`${
                          (teamMemberData.locationDetails.pending /
                            teamMemberData.locationDetails.total) *
                          100
                        } ${
                          (teamMemberData.locationDetails.total -
                            teamMemberData.locationDetails.pending /
                              teamMemberData.locationDetails.total) *
                          100
                        }`}
                        strokeWidth={10}
                        strokeDashoffset={
                          -(
                            teamMemberData.locationDetails.completed /
                            teamMemberData.locationDetails.total
                          ) * 100
                        } // Adjusted to start from the end point of the second slice
                        slice-title="Pending"
                        percent-value={2}
                        usage={2}
                        total-val={20}
                      >
                        <animate
                          attributeName="stroke-dashoffset"
                          from={100}
                          to={-100} // Adjusted to start from the end point of the second slice
                          dur="0.5s"
                        />
                      </circle>
                      <circle
                        show-tip="ctDonutTip"
                        className="ct-donut-slice"
                        cx="50%"
                        cy="50%"
                        fill="transparent"
                        r="15.909091"
                        stroke="#C52A38" // Rejected color
                        strokeDasharray={`${
                          (teamMemberData.locationDetails.rejected /
                            teamMemberData.locationDetails.total) *
                          100
                        } ${
                          (teamMemberData.locationDetails.total -
                            teamMemberData.locationDetails.rejected /
                              teamMemberData.locationDetails.total) *
                          100
                        }`}
                        strokeWidth={10}
                        strokeDashoffset={
                          -(
                            (teamMemberData.locationDetails.completed +
                              teamMemberData.locationDetails.pending) /
                            teamMemberData.locationDetails.total
                          ) * 100
                        } // Adjusted to start from the end point of the second slice
                        slice-title="Rejected"
                        percent-value={7}
                        usage={7}
                        total-val={20}
                      >
                        <animate
                          attributeName="stroke-dashoffset"
                          from={100}
                          to={-100} // Adjusted to start from the end point of the second slice
                          dur="0.5s"
                        />
                      </circle>
                      <circle cx="50%" cy="50%" fill="#FFFFFF" r={16}></circle>

                      <text
                        x="50%"
                        y="52%"
                        className={`ct-donut-center ${classes.svg_TotalNumber}`}
                      >
                        {teamMemberData.locationDetails.total.toLocaleString(
                          "en-US",
                          {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                          }
                        )}
                      </text>
                      <text
                        x="51%"
                        y="65%"
                        className={`ct-donut-center ${classes.svg_TotalText}`}
                      >
                        Total
                      </text>
                    </svg>
                    <div className="ct-tip" />
                  </div>
                  <div>
                    <div>
                      <Typography style={{ display: "flex" }}>
                        <Avatar
                          sx={{
                            bgcolor: `#1273B8`,
                            width: "13px",
                            height: "13px",
                            marginRight: "5px",
                            marginTop: "5px",
                          }}
                        >
                          {" "}
                        </Avatar>{" "}
                        {ln.reached} :{" "}
                        {teamMemberData.locationDetails.completed}
                      </Typography>
                    </div>
                    <div>
                      <Typography style={{ display: "flex" }}>
                        <Avatar
                          sx={{
                            bgcolor: `#240051`,
                            width: "13px",
                            height: "13px",
                            marginRight: "5px",
                            marginTop: "5px",
                          }}
                        >
                          {" "}
                        </Avatar>{" "}
                        {ln.pending} : {teamMemberData.locationDetails.pending}
                      </Typography>
                    </div>
                    <div>
                      <Typography style={{ display: "flex" }}>
                        <Avatar
                          sx={{
                            bgcolor: `#C52A38`,
                            width: "13px",
                            height: "13px",
                            marginRight: "5px",
                            marginTop: "5px",
                          }}
                        >
                          {" "}
                        </Avatar>{" "}
                        {ln.rejected} :{" "}
                        {teamMemberData.locationDetails.rejected}
                      </Typography>
                    </div>
                  </div>
                </div>
              </Paper>
            </div>
          ) : null}
          <div className={classes.displayFlexContainerSpaceBetween}>
            <div className={classes.displayflexnrmlContainer}>
              <Typography className={classes.teamPageHeading}>
                {ln.location}
              </Typography>
              <div className={classes.viewbittonContainer}>
                <Button
                  className={`${classes.button} ${
                    activeButton === "grid" ? `${classes.active}` : ""
                  }`}
                  onClick={() => handleButtonClick("grid")}
                >
                  <BsFillGrid3X3GapFill />
                </Button>
                <Button
                  className={`${classes.button} ${
                    activeButton === "list" ? `${classes.active}` : ""
                  }`}
                  onClick={() => handleButtonClick("list")}
                >
                  <FaList />
                </Button>
              </div>
            </div>
            <div
              style={{ display: "flex", columnGap: 20, alignItems: "center" }}
            >
              <div>
                <Button
                  variant="outlined"
                  className={classes.filterbtns}
                  onClick={(e) => handleMenuclick(e, setAnchorEl)}
                  style={{ background: "white" }}
                >
                  <div>
                    <FilterIcon /> All
                  </div>
                  <div style={{ marginLeft: "5px" }}>
                    <ArrowDownIcon />
                  </div>
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <div className={classes.filterHeading}>
                    <h5>Filter</h5>
                    <span
                      className={classes.claerBtn}
                      onClick={() => clickClearAll(setSelectedValues, "array")}
                    >
                      clear all
                    </span>
                  </div>
                  {locationStatusFilter.map((item, index) => {
                    return (
                      <MenuItem
                        key={index}
                        style={{
                          padding: "6px 25px 6px 0",
                          textTransform: "capitalize",
                        }}
                      >
                        <Form.Check
                          style={{
                            margin: 10,
                            marginRight: 15,
                          }}
                          checked={locationFilteredStatus?.includes(item)}
                          onChange={() => handleStatusCheckbox(item)}
                          name="group1"
                          type="checkbox"
                        />
                        {/* <Checkbox
                          checked={selectedValues.includes(item)}
                          onChange={() => handleStatusCheckbox(item)}
                        /> */}
                        {item.replaceAll("_", " ")}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </div>
              <div>
                <Button
                  variant="outlined"
                  className={classes.filterbtns}
                  onClick={(e) => handleMenuclick(e, setAnchorEl1)}
                  style={{ background: "white" }}
                >
                  <div>
                    <SortIcons />{" "}
                    {selectedValues1
                      ? selectedValues1?.replaceAll("_", " ")
                      : "Newest to Oldest"}
                  </div>
                  <div>
                    <ArrowDownIcon />
                  </div>
                </Button>
                <Menu
                  anchorEl={anchorEl1}
                  open={Boolean(anchorEl1)}
                  onClose={handleMenuClose}
                >
                  <div className={classes.filterHeading}>
                    <h5>Sort By</h5>
                    <span
                      className={classes.claerBtn}
                      onClick={() =>
                        clickClearAll(setSelectedValues1, "single")
                      }
                    >
                      clear all
                    </span>
                  </div>
                  {locationTimeFilter.map((item, index) => {
                    return (
                      <MenuItem
                        key={index}
                        style={{
                          padding: "6px 25px 6px 0",
                          textTransform: "capitalize",
                        }}
                      >
                        <Form.Check
                          style={{
                            margin: 10,
                            marginRight: 15,
                          }}
                          checked={selectedValues1?.includes(item)}
                          onChange={() => handleTimeCheckBox(item)}
                          name="group1"
                          type="checkbox"
                        />
                        {/* <Checkbox
                          checked={selectedValues1 === item}
                          onChange={() => handleTimeCheckBox(item)}
                        /> */}
                        {item.replaceAll("_", " ")}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </div>
            </div>
          </div>
          {teamMemberData &&
          Array.isArray(teamMemberData.locationDetails.list) ? (
            teamMemberData.locationDetails.list.length > 0 ? (
              <div>
                {activeButton === "grid" ? (
                  <div className={classes.GridContainer}>
                    {teamMemberData.locationDetails.list.map((item, index) => (
                      <React.Fragment key={index}>
                        <Card
                          key={item.Location._id}
                          className={classes.teamCard}
                          style={{ height: "225px" }}
                          onClick={(e) => handleCardClick(e, item)}
                        >
                          <CardContent
                            sx={{ paddingBottom: "16px !important" }}
                            className={classes.teamCardContent}
                          >
                            <div className={classes.locationGridCardHeader}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  columnGap: "10px",
                                  width: "70%",
                                }}
                              >
                                <Badge
                                  color={
                                    isOnline === "online" ? "success" : "error"
                                  }
                                  badgeContent=" "
                                  overlap="circular"
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                  variant="dot"
                                >
                                  <Avatar
                                    {...stringAvatar(item.Location.title)}
                                    sx={{
                                      bgcolor: `${generateColor(
                                        item.Location.title
                                      )}`,
                                    }}
                                  />
                                </Badge>
                                <div className={classes.memberCardText}>
                                  <Typography
                                    className={classes.memberCardTextHeading}
                                  >
                                    {item.Location.title}
                                  </Typography>
                                </div>
                              </div>
                              <Chip
                                className={classes.locationStatusChip}
                                label={item.status}
                                size="small"
                                style={getChipColor(item.status)}
                              />
                            </div>
                            <hr className={classes.UnderlineHr} />
                            <div>
                              <div className={classes.memberCardText}>
                                <Typography
                                  className={`${classes.form_lable} ${classes.teamCardTextMain}`}
                                >
                                  {ln.assigned_on}
                                </Typography>
                                <Typography
                                  className={`${classes.memberCardTextHeading}`}
                                >
                                  {moment(item.Location.assigned_on).format(
                                    "DD MMM YYYY, hh:mm A"
                                  )}
                                </Typography>
                              </div>
                            </div>
                            <hr className={classes.UnderlineHr} />
                            <div>
                              {item.status === "completed" ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    style={{ width: "45%" }}
                                    className={classes.locationCardButton}
                                  >
                                    {ln.view_route}
                                  </Button>
                                  <Button
                                    variant="contained"
                                    type="submit"
                                    size="small"
                                    style={{ width: "45%" }}
                                    className={classes.locationCardButton}
                                  >
                                    {ln.not_reached}?
                                  </Button>
                                </div>
                              ) : (
                                <div>
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    className={classes.locationCardButton}
                                  >
                                    {ln.view_route}
                                  </Button>
                                </div>
                              )}
                            </div>
                          </CardContent>
                        </Card>
                      </React.Fragment>
                    ))}
                  </div>
                ) : (
                  <MyTableBody list={teamMemberData.locationDetails.list} />
                )}
              </div>
            ) : (
              <Typography variant="h6" className={classes.noLocationsText}>
                No Locations Found
              </Typography>
            )
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default TeamMemberLocaions;
