import React, { useState } from "react";
import AppLogo from "../assets/images/logo-dark.png";
import smallLogo from "../assets/images/logo-small.png";
import classes from "./index.module.css";
import profile from "../assets/images/avatar-3.jpg";
import nonotification from "../assets/images/noNotification.png";
import {
  Typography,
  Avatar,
  Menu,
  MenuItem,
  Button,
  ClickAwayListener,
  Modal,
  Badge,
  Box,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { authApi, getAuthorizationHeader } from "../api-calls/user-requests";
import {
  GET_NOTIFICATIONS,
  GET_USER,
  ImageUrl,
  UPDATE_NOTIFICATION,
} from "../api-calls/api-urls";
import {
  ToggleSideBar,
  setCurrentUser,
  setHeaderName,
  setNotificationList,
} from "../Redux/actions/user-actions";
import { getLanguagedataFunction } from "../utilities/language";
import LanguageModal from "./LanguageModal";
import {
  AboutusIcon,
  CallIcon,
  FeedBackIcon,
  LanguageChangeIcon,
  LogoutIcon,
  MenuBar,
  MyProfileIcon,
  NotificationBellIcon,
  PolicyIcon,
  ResetpasswordIcon,
} from "./Icons";
import { Padding } from "@mui/icons-material";
import { setPresenceOffline, setPresenceOnline } from "../utilities/Xmpp";
import {
  generateColor,
  getNotificationUnseenCount,
  stringAvatar,
} from "../utilities/commonFuntions";
import NotificationItem from "./notificationItem";
import Cookies from "js-cookie";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(false);
  const rootRef = React.useRef(null);
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const user = useSelector((state) => state.reducers.allUsers.logedUser);
  const [logoutModal, setLogoutModal] = useState(false);
  const [moddleToggle, setModdleToggle] = useState(false);
  const { headerName, notificationList } = useSelector(
    (state) => state.reducers.allUsers
  );
  const [openNotification, setOpenNotification] = useState(true);

  // const [currentUser, setCurrentUser] = useState();
  const currentUser = useSelector(
    (state) => state.reducers.allUsers.current_user
  );
  const open = Boolean(anchorEl);
  const ln = useSelector((state) => state.reducers.allUsers.language_json);
  const togglesidebar = useSelector(
    (state) => state.reducers.allUsers.togglesidebar
  );
  const [menuopen, setMenuOpen] = React.useState(false);

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  const handleClickOpen = () => {
    setMenuOpen(!menuopen);
  };

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeModal = () => {
    setModdleToggle(false);
    setLogoutModal(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setPresenceOffline(userInfo?._id);
    Cookies.remove("userToken");
    sessionStorage.removeItem("userInfo");
    localStorage.clear();
    handleClose();
    navigate("/login");
  };

  const handleChangeLanguage = () => {
    handleClose();
    setModdleToggle(true);
  };
  const handleMyProfileClick = () => {
    handleClose();
    navigate("/my-profile");
  };
  const handleResetPassword = () => {
    handleClose();
    dispatch(setHeaderName("Reset Password"));
    navigate("/reset-password");
  };

  const handleCloseNotification = () => {
    setMenuOpen(false);
  };

  const handleMarkAsRead = (id) => {
    const { data } = authApi.put(
      `${UPDATE_NOTIFICATION}/${id}`,
      {},
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );
    console.log(data);
    getNotifications();
  };

  useEffect(() => {
    const path = window.location.pathname;
    const Arr = path.split("/");
    const name = Arr[1].replaceAll("-", "_").toString();
    //get lable from json
    if (ln) {
      const lable = ln[name];
      dispatch(setHeaderName(lable));
    }
  }, [ln]);

  const getCurrentLoggedUser = async (id) => {
try {
  const { data } = await authApi.get(`${GET_USER}/${id}`, {
    headers: { Authorization: getAuthorizationHeader() },
  });
  if (data.message === "User not found") {
    handleLogout();
  } else {
    dispatch(setCurrentUser(data));
  }
} catch (error) {
  console.log(error);
  
}
  };

  const handleContactUS = () => {
    handleClose();
    navigate("/contact-us");
  };

  const handleAboutUsClick = () => {
    handleClose();
    navigate("/about-us");
    dispatch(setHeaderName("About Us"));
  };

  const handlePoliciesClick = () => {
    handleClose();
    navigate("/policies");
    dispatch(setHeaderName("Policies"));
  };

  const handleFeedback = () => {
    handleClose();
    navigate("/feedback");
  };

  const handleSideBarToggle = () => {
    dispatch(ToggleSideBar(!togglesidebar));
  };

  const handleViewAllNotification = () => {
    dispatch(setHeaderName("Notifications"));
    setMenuOpen(false);
    navigate("/notifications");
  };

  useEffect(() => {
    const token = Cookies.get("userToken");
    if (userInfo && userInfo?._id) {
      getCurrentLoggedUser(userInfo?._id);
    } else if (token) {
      const userData = JSON.parse(token);
      getCurrentLoggedUser(userData?._id);
    } else {
      getCurrentLoggedUser(user?._id);
    }
  }, [!currentUser]);

  useEffect(() => {
    getNotifications();
  }, []);

  const getNotifications = async () => {
try {
  const { data } = await authApi.get(
    `${GET_NOTIFICATIONS}/${userInfo?._id}`,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  console.log("Notification List ====>", data);
  dispatch(setNotificationList(data));
} catch (error) {
  console.log(error);
  
}
  };

  const handleAllMarkAsRead = () => {
    const { data } = authApi.put(
      `${UPDATE_NOTIFICATION}/all`,
      {},
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );
    console.log(data);
    getNotifications();
  };

  return (
    <div>
      {ln ? (
        <div className={`${classes.header_flex} ${classes.header_maindiv}`}>
          <div className={`${classes.header_flex} ${classes.second_maindiv}`}>
            <div>
              <span className="logo-lg mb-5">
                {togglesidebar ? (
                <div className={classes.HeaderLogo_div}>
                    <img
                    src={smallLogo}
                    alt=""
                    className={classes.HeaderLogoImage}
                  />
                  <p>
                    Team Player
                  </p>
                </div>
                ) : (
                  <img
                    src={smallLogo}
                    alt=""
                    className={classes.HeaderSmallLogoImage}
                    width={"32px"}
                  />
                )}
              </span>
            </div>
            <div
              className={`${classes.header_flex} ${classes.header_MenuIcon}`}
            >
              <span onClick={() => handleSideBarToggle()}>
                <MenuBar />
              </span>
              <Typography >
                {headerName}
              </Typography>
            </div>
          </div>
          {currentUser ? (
            <div
              className={`${classes.header_flex} ${classes.second_maindiv}`}
              style={{
                columnGap: "20px",
              }}
            >
              <div style={{ position: "relative" }}>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <div>
                    <HtmlTooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      className={classes.TooltipClasses}
                      onClose={handleCloseNotification}
                      open={menuopen}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={
                        <React.Fragment>
                          <div className={classes.tooltip_arrow} />
                          <div className={classes.menuItem}>
                            <Typography className={classes.TooltipHEader}>
                              Notification
                              <span className={classes.unseencount}>
                                {getNotificationUnseenCount(notificationList)}
                              </span>
                            </Typography>
                            <Button
                              variant="text"
                              className={`${classes.TooltipmarkText}`}
                              onClick={() => handleAllMarkAsRead()}
                            >
                              Mark all as read
                            </Button>
                          </div>
                          {notificationList && notificationList.length > 0 ? (
                            <div>
                              <div
                                className={classes.notificationListContainer}
                              >
                                {notificationList.map((item, index) => (
                                  <NotificationItem
                                    item={item}
                                    key={index}
                                    handleMarkAsRead={handleMarkAsRead}
                                  />
                                ))}
                              </div>
                              <div className={classes.viewAllbtnSection}>
                                <Button
                                  variant="text"
                                  className={`${classes.TooltipmarkText}`}
                                  onClick={() => handleViewAllNotification()}
                                >
                                  {ln.view_all}
                                </Button>
                              </div>
                            </div>
                          ) : (
                            <div className={classes.NoImaheContainer}>
                              <img src={nonotification} alt="" />
                            </div>
                          )}
                        </React.Fragment>
                      }
                    >
                      <Badge
                        badgeContent={getNotificationUnseenCount(
                          notificationList
                        )}
                        color="secondary"
                        variant="outlined"
                        onClick={handleClickOpen}
                        sx={{ Padding: 0, border: "none", minWidth: 0 }}
                        className={classes.HeaderNotificationButton}
                      >
                        <NotificationBellIcon />
                      </Badge>
                    </HtmlTooltip>
                  </div>
                </ClickAwayListener>
                {/* <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">Tooltip with HTML</Typography>
                    <em>{"And here's"}</em> <b>{"some"}</b>{" "}
                    <u>{"amazing content"}</u>. {"It's very engaging. Right?"}
                  </React.Fragment>
                }
              ></HtmlTooltip> */}
              </div>
              <div
                className={`${classes.header_flex} ${classes.AvatarDiv}`}
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                {userInfo &&
                userInfo.profile_pic &&
                userInfo.profile_pic?.destination ? (
                  <Avatar
                    alt="Remy Sharp"
                    src={`${process.env.REACT_APP_IMAGE_URL}/${userInfo?.profile_pic?.destination}${userInfo?.profile_pic?.filename}`}
                  />
                ) : (
                  <Avatar
                    {...stringAvatar(
                      `${currentUser.first_name.trim()} ${currentUser.last_name.trim()}`
                    )}
                    sx={{
                      bgcolor: `${generateColor(
                        `${currentUser.first_name} ${currentUser.last_name}`
                      )}`,
                    }}
                  />
                )}
                <div>
                  <div
                    id="basic-button"
                    className={classes.HeaderUser_Name}
                    style={{
                      textTransform: "capitalize",
                    }}
                  >
                    {`${currentUser.first_name} ${currentUser.last_name}`}
                    <p className={classes.HeaderUser_designation}>
                      {currentUser.designation}
                    </p>
                  </div>
                </div>
                <svg
                  style={{ marginLeft: "10px" }}
                  width="10"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.94894 0.812821L5.00433 3.64107L8.05972 0.812821C8.36683 0.528539 8.86294 0.528539 9.17006 0.812821C9.47717 1.0971 9.47717 1.55633 9.17006 1.84061L5.55556 5.1864C5.24844 5.47068 4.75234 5.47068 4.44522 5.1864L0.830727 1.84061C0.523612 1.55633 0.523612 1.0971 0.830727 0.812821C1.13784 0.535828 1.64182 0.528539 1.94894 0.812821Z"
                    fill="white"
                  />
                </svg>
              </div>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.MenuItems}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={handleMyProfileClick}
                  className={classes.HeaderMenuItem}
                >
                  <MyProfileIcon /> {ln.my_profile}
                </MenuItem>
                <hr />
                <MenuItem
                  onClick={handleChangeLanguage}
                  className={classes.HeaderMenuItem}
                >
                  <LanguageChangeIcon /> {ln.language}
                </MenuItem>
                <MenuItem
                  onClick={handleResetPassword}
                  className={`${classes.HeaderMenuItem} ${classes.resetPAsswordMenu}`}
                >
                  <ResetpasswordIcon /> {ln.reset_password}
                </MenuItem>
                <MenuItem
                  onClick={handleContactUS}
                  className={`${classes.HeaderMenuItem} ${classes.CallIconHeader}`}
                >
                  <CallIcon /> {ln.contact_us}
                </MenuItem>
                <MenuItem
                  onClick={handleFeedback}
                  className={classes.HeaderMenuItem}
                >
                  <FeedBackIcon /> {ln.feedback}
                </MenuItem>
                <MenuItem
                  onClick={handleAboutUsClick}
                  className={classes.HeaderMenuItem}
                >
                  <AboutusIcon /> {ln.about_us}
                </MenuItem>
                <MenuItem
                  onClick={handlePoliciesClick}
                  className={`${classes.HeaderMenuItem} ${classes.policyHeaderMenu}`}
                >
                  <PolicyIcon /> {ln.policies}
                </MenuItem>
                <MenuItem
                  onClick={() => setLogoutModal(true)}
                  className={classes.HeaderMenuItem}
                >
                  <LogoutIcon /> {ln.logout}
                </MenuItem>
              </Menu>
              {moddleToggle ? <LanguageModal modalClose={closeModal} /> : null}
            </div>
          ) : null}
        </div>
      ) : null}
      {logoutModal ? (
        <Modal
          open={logoutModal}
          onClose={() => closeModal()}
          aria-labelledby="server-modal-title"
          data-testid="register_modal"
          aria-describedby="server-modal-description"
          sx={{
            display: "flex",
            height: 480,
            marginTop: 10,
            justifyContent: "center",
          }}
          container={() => rootRef.current}
        >
          <Box
            sx={{
              position: "relative",
              bgcolor: "background.paper",
              borderRadius: 1,
              boxShadow: (theme) => theme.shadows[5],
            }}
            style={{
              width: "460px",
              height: "max-content",
              top: "20%",
            }}
            className={classes.loginModalWidth}
          >
            <div className={classes.deleteConformationFirstDiv}>
              <Typography
                className={`mb-2 ${classes.imagePageText}`}
                style={{ width: "100%" }}
              >
                {ln.logout}
              </Typography>
              <Typography className={classes.successPageSubText}>
                {ln.are_you_sure_you_want_to_logout}
              </Typography>
              <div className={classes.addMemberModalbtnSection}>
                <Button variant="outlined" onClick={() => closeModal()}>
                  {ln.cancel}
                </Button>
                <Button variant="contained" onClick={() => handleLogout()}>
                  {ln.logout}
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      ) : null}
    </div>
  );
};

export default Header;
