import React, { useEffect, useRef, useState } from "react";
import classes from "./chat.module.css";
import { Avatar, Typography, Button } from "@mui/material";
import { LiaCheckDoubleSolid } from "react-icons/lia";
import moment from "moment";
import { FaPlay, FaPause } from "react-icons/fa";
import { AudioPlayIcon } from "../../../components/Icons";
import WaveSurfer from "wavesurfer.js";
import { generateColor, stringAvatar } from "../../../utilities/commonFuntions";

const MessageComponent = ({
  userInfo,
  message,
  index,
  openProfile,
  selectedUserProfile,
}) => {
  // const [currentTime, setCurrentTime] = useState(0);
  // const [audio, setAudio] = useState();
  // const [duration, setDuration] = useState(0);
  // const waveSurferRef = useRef({
  //   isPlaying: () => false,
  // });
  // const [isPlaying, toggleIsPlaying] = useState(false);
  // const isComponentMounted = useRef(true);

  // useEffect(() => {
  //   // Set the flag to true when the component is mounted
  //   isComponentMounted.current = true;

  //   // Cleanup function to set the flag to false when the component is unmounted
  //   return () => {
  //     isComponentMounted.current = false;
  //   };
  // }, []);

  // useEffect(() => {
  //   if (message.file_name.includes("mp3")) {
  //     setAudio(message.file_url);
  //   }
  // }, [message]);

  // useEffect(() => {
  //   const WavesurferId = `waveform${index}`;

  //   const initializeWaveSurfer = () => {
  //     const waveSurfer = WaveSurfer.create({
  //       container: `#${WavesurferId}`,
  //       hideScrollbar: true,
  //       waveColor: "#CDD5E5",
  //       progressColor: "blue",
  //       cursorColor: "black",
  //       barWidth: 3,
  //       barHeight: 10,
  //       minPxPerSec: 10,
  //       barGap: 2,
  //       height: 18,
  //     });

  //     waveSurfer.load(audio);

  //     waveSurfer.on("ready", () => {
  //       setDuration(waveSurfer.getDuration());
  //       waveSurferRef.current = waveSurfer;
  //     });

  //     waveSurfer.on("audioprocess", () => {
  //       setCurrentTime(waveSurfer.getCurrentTime());
  //     });

  //     return waveSurfer;
  //   };

  //   if (isComponentMounted.current) {
  //     const waveSurferInstance = initializeWaveSurfer();

  //     // Cleanup function to destroy the WaveSurfer instance when the component is unmounted
  //     return () => {
  //       waveSurferInstance.destroy();
  //     };
  //   }
  // }, [audio, index]);

  // const formatTime = (time) => {
  //   const minutes = Math.floor(time / 60);
  //   const seconds = Math.floor(time % 60);
  //   return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  // };

  return (
    <div
      key={index}
      className={
        message.sender_id === userInfo._id
          ? `${classes.sentMessageContainer} mb-3`
          : `${classes.receivedMessageContainer} mb-3`
      }
    >
      <div className={classes.Messagecontainer}>
        {message.sender_id === userInfo._id ? (
          <div>
            {userInfo &&
            userInfo?.profile &&
            userInfo?.profile_pic?.filename ? (
              <Avatar
                alt="Remy Sharp"
                src={`${process.env.REACT_APP_IMAGE_URL}/${userInfo?.profile_pic?.destination}${userInfo?.profile_pic?.filename}`}
              />
            ) : (
              <Avatar
                {...stringAvatar(`${userInfo?.name?.trim()}`)}
                sx={{
                  bgcolor: `${generateColor(`${userInfo?.name?.trim()}}`)}`,
                }}
                style={{
                  width: "16px",
                  height: "16px",
                  fontSize: "8px",
                  fontWeight: 600,
                  textTransform: "uppercase",
                }}
              />
            )}
          </div>
        ) : (
          <div>
            {message.senderInfo &&
            message.senderInfo?.profile_pic &&
            selectedUserProfile?.profile_pic?.filename ? (
              <Avatar
                alt="Remy Sharp"
                src={`https://api.tp.apps.org.in/api/${selectedUserProfile?.profile_pic?.destination}${selectedUserProfile?.profile_pic?.filename}`}
              />
            ) : (
              <Avatar
                {...stringAvatar(`${message.senderInfo?.name?.trim()}`)}
                sx={{
                  bgcolor: `${generateColor(
                    `${message.senderInfo?.name?.trim()}`
                  )}`,
                }}
                style={{
                  width: "16px",
                  height: "16px",
                  fontSize: "8px",
                  fontWeight: 600,
                  textTransform: "uppercase",
                }}
              />
            )}
          </div>
        )}
        {message.message !== "" ? (
          <div
            className={classes.Message}
            style={{ maxWidth: openProfile ? "400px" : "500px" }}
          >
            <Typography style={{wordBreak:"break-word"}}>{message.message}</Typography>
            {message.sender_id === userInfo._id && message.status === "seen" ? (
              <LiaCheckDoubleSolid className={classes.seenIcon} />
            ) : message.sender_id === userInfo._id &&
              message.status === "unseen" ? (
              <LiaCheckDoubleSolid className={classes.unseenIcon} />
            ) : null}
          </div>
        ) : (
          <div
            className={classes.Message}
            style={{ maxWidth: openProfile ? "400px" : "500px", padding: 5 }}
          >
            {message?.file_name?.includes("mp3") ? (
              <div>
                <audio controls>
                  <source src={message?.file_url} type="audio/ogg"></source>
                  <source src={message?.file_url} type="audio/mpeg"></source>
                </audio>
              </div>
            ) : (
              <img
                src={message?.file_url}
                alt=""
                className={classes.FileImage}
              />
            )}
          </div>
        )}
        <Typography className={classes.Chat_lastMessage}>
          {moment(message?.time).format("hh:mm A")}
        </Typography>
      </div>
    </div>
  );
};

export default MessageComponent;
