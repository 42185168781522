import React, { useRef, useState } from "react";
import {
  Paper,
  Typography,
  TextField,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import classes from "./index.module.css";
import image from "../../../assets/images/XMLID_1492_.png";
import whatsapp from "../../../assets/images/whatsappLogo.png";
import insta from "../../../assets/images/instaLogo.png";
import linkedin from "../../../assets/images/linkedinLogo.png";
import mail from "../../../assets/images/mailLogo.png";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useNavigate } from "react-router-dom";
import { BrudCrumbBackIcon, DeleteBinIcons } from "../../../components/Icons";
import { useEffect } from "react";
import { setHeaderName } from "../../../Redux/actions/user-actions";
import { useDispatch, useSelector } from "react-redux";
import { CREATE_CONTACTUS, UPLOAD_IMAGE } from "../../../api-calls/api-urls";
import {
  authApi,
  authImageApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import SuccessModal from "./successModal";
import { getLanguagedataFunction } from "../../../utilities/language";
import AlertBox from "../../../components/AlertBox";

const Contactus = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputFile = useRef(null);
  const [error, setError] = useState("");
  const [modalToggle, setModalToggle] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [contactUsImage, setContactUsImage] = useState([]);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const ln = useSelector((state) => state.reducers.allUsers.language_json);

  const emailAddress = "info@teamplayer.com";
  const heading = "Please Enter Subject Here";
  const body = "please Enter you problem here";

  const mailtoLink = `mailto:${emailAddress}}`;

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  useEffect(() => {
    dispatch(setHeaderName("Contact us"));
  }, [window.location.pathname]);

  const CreateimageArray = async (items) => {
    console.log(items);
    const array = items.map(async (file) => {
      let formData = new FormData();
      formData.append("file", file);
      const { data } = await authImageApi.post(UPLOAD_IMAGE, formData, {
        headers: { Authorization: getAuthorizationHeader() },
      });
      return data.data;
    });
    const results = await Promise.all(array);
    const finalArray = [...results];
    return finalArray;
    //return results;
    // setFiles([...files, ...results]);
    // dispatch(saveImageList([...files, ...results]));
  };
  const handleSubmit = async () => {
    var Buildimages = (await CreateimageArray(contactUsImage)) || [];
    const userEntry = {
      subject: subject,
      message: message,
      images: Buildimages,
    };
    try {
      console.log(userEntry);
      const { data } = authApi.post(CREATE_CONTACTUS, userEntry, {
        headers: { Authorization: getAuthorizationHeader() },
      });
      console.log(data);
      setModalToggle(true);
      // clearData();
    } catch (err) {
      console.log(err.response.data.message);
      setError(err.response.data.message);
      setShowAlert(true);
    }
  };

  const clearData = () => {
    setSubject("");
    setMessage("");
    setContactUsImage([]);
  };

  const deleteFile = () => {
    setContactUsImage([]);
  };

  const closeForm = () => {
    setModalToggle(false);
    clearData();
  };

  const onButtonClick = () => {
    inputFile.current.click();
  };
  const handleFileUpload = (e) => {
    const { files } = e.target;
    if (files && files.length) {
      const filename = files[0].name;
      var parts = filename.split(".");
      const fileType = parts[parts.length - 1];
      console.log("fileType", fileType); //ex: zip, rar, jpg, svg etc.
      console.log(files);
      setContactUsImage([...files]);
    }
  };

  return (
    <div>
      {ln && (
        <div>
          <div className={`${classes.brudCrumb} mb-4 `}>
            <span onClick={() => navigate(-1)}>
              <BrudCrumbBackIcon />
            </span>
            <span
              onClick={() => navigate("/location")}
              className={classes.form_lable}
              style={{ color: "#0A1F41" }}
            >
              {ln.contact_us}
            </span>
          </div>
          <Paper className={classes.ContactusMaindiv}>
            <div>
              <img src={image} alt="Avatar" width={"95%"} />
              <div className={classes.ImagePageTextContainr}>
                <Typography className={classes.CuntactUsHeading}>
                  {ln.or_connect_with_us_on}
                </Typography>
                <Typography className={classes.CuntactUsHeading}>
                  {ln.follow_us_on}
                </Typography>
                <div className={classes.ContactusLogoContainer}>
                  <span className={classes.LogosContainer}>
                    <a href={mailtoLink} target="_blank" rel="noreferrer">
                      <img src={mail} alt="" />
                    </a>
                  </span>
                  <div>
                    <Typography className={classes.form_lable}>
                      {ln.email_us}:
                    </Typography>
                    <Typography className={classes.mainText}>
                      info@teamplayer.com
                    </Typography>
                  </div>
                </div>
                <div className={classes.ContactusLogoContainer}>
                  <span className={classes.LogosContainer}>
                    <a
                      href="https://www.instagram.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={insta} alt="" />
                    </a>
                  </span>
                  <span
                    className={classes.LogosContainer}
                    style={{
                      marginLeft: "5px",
                    }}
                  >
                    <a
                      href="https://www.linkedin.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={linkedin} alt="" />
                    </a>
                  </span>
                </div>
                <div className={classes.ContactusLogoContainer}>
                  <span className={classes.LogosContainer}>
                    <a
                      href="https://wa.me/9042020205"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={whatsapp} alt="" />
                    </a>
                  </span>

                  <div>
                    <Typography className={classes.form_lable}>
                      {ln.chat_with_us}:
                    </Typography>
                    <Typography className={classes.mainText}>
                      +91 986754321
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            <Paper>
              <ValidatorForm
                useref="form"
                fullwidth
                onSubmit={handleSubmit}
                className={classes.ContactusForm}
              >
                <Typography className={`mb-3 ${classes.CuntactUsFormHeading}`}>
                  {ln.any_issue_or_concern}.
                </Typography>
                <div className="mb-3">
                  <TextValidator
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    id="address"
                    value={subject}
                    fullWidth
                    placeholder="Subject"
                    onChange={(e) => setSubject(e.target.value)}
                    size="small"
                    validators={["required"]}
                    errorMessages={["Subject is required"]}
                  />
                </div>
                <div className="mb-3">
                  <TextValidator
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    id="outlined-multiline-static"
                    value={message}
                    multiline
                    rows={4}
                    fullWidth
                    placeholder="Message"
                    onChange={(e) => setMessage(e.target.value)}
                    validators={["required"]}
                    errorMessages={["Message is required"]}
                  />
                </div>
                <div>
                  <div className={`mb-1 ${classes.contactformdropSection}`}>
                    <input
                      style={{ display: "none" }}
                      // accept=".zip,.rar"
                      ref={inputFile}
                      onChange={handleFileUpload}
                      type="file"
                      multiple
                    />
                    <Typography
                      className={`${classes.contactdropText} ${classes.CuntactUsFormHeading}`}
                      onClick={onButtonClick}
                      style={{ cursor: "pointer" }}
                    >
                      {ln.add_image_and_documents}{" "}
                      <span className={classes.browseFileTexr}>
                        {ln.browse_files}
                      </span>
                    </Typography>
                  </div>
                  {contactUsImage && contactUsImage.length > 0 && (
                    <div className="d-flex justify-content-between align-items-center">
                      <Typography className={classes.fileText}>
                        {contactUsImage[0]?.name}
                      </Typography>
                      <span onClick={deleteFile}>
                        <DeleteBinIcons />{" "}
                      </span>
                    </div>
                  )}
                </div>
                <Button
                  variant="contained"
                  type="submit"
                  className={classes.ContactusFormButton}
                >
                  {ln.send}
                </Button>
              </ValidatorForm>
            </Paper>
          </Paper>
          {modalToggle ? <SuccessModal modalClose={closeForm} /> : null}
          {showAlert && error && (
            <AlertBox type="error" message={error} stateName={setShowAlert} />
          )}
        </div>
      )}
    </div>
  );
};

export default Contactus;
