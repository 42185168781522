import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  Badge,
  Chip,
  Avatar,
  Menu,
  MenuItem,
  Dialog,
  Button,
  Checkbox,
} from "@mui/material";
import { DateRangePicker } from "react-date-range";
import { locationStatusFilter, predefinedRanges } from "../utilities/constants";
import { handleSortClick } from "../utilities/commonFuntions";
import classes from "./index.module.css";
import moment from "moment";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Form } from "react-bootstrap";
import { useEffect } from "react";
import { Snackbar, Alert } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getLanguagedataFunction } from "../utilities/language";
import {
  FilterFunnelIcon,
  OppositeSortArrawBtnIcons,
  SortArrawBtnIcons,
} from "./Icons";
import {
  saveLocationMembersFilter,
  saveLocationStatusFilter,
  saveLoctionRangeFilter,
} from "../Redux/actions/location-action";
import { authApi, getAuthorizationHeader } from "../api-calls/user-requests";
import { GET_USER, TEAM_MEMBERS_LIST } from "../api-calls/api-urls";
import { useNavigate } from "react-router-dom";
import { addDays } from "date-fns";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import LocationListItem from "./LocationListItem";

// import {
//   locationStatusFilter,
//   locationTimeFilter,
// } from "../utilities/constants";

const data = [
  { id: 1, name: "John Doe", age: 25 },
  { id: 2, name: "Jane Smith", age: 30 },
  { id: 3, name: "Bob Johnson", age: 40 },
];

const MyTableBody = (props) => {
  console.log(props);
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState();
  const [anchorEl1, setAnchorEl1] = useState();
  // const [selectedValues, setSelectedValues] = useState(["1"]); // State to store selected values
  // const [selectedValues1, setSelectedValues1] = useState([]);
  const [selectedMembers, setSeletedMembers] = useState([]);
  const [teamMembersList, setTeamMembersList] = useState();
  const [selected, setSelected] = useState();
  const [openMenu, setOpenMenu] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState("");
  const [loggedUser, setLoggedUser] = useState();
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const ln = useSelector((state) => state.reducers.allUsers.language_json);
  const { locationRangeFilter, locationFilteredStatus } = useSelector(
    (state) => state.reducers.location
  );
  const { sortName } = useSelector((state) => state.reducers.allUsers);
  const [showCalendar, setShowCalendar] = React.useState(false);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  useEffect(() => {
    if (showAlert) {
      const timeout = setTimeout(() => {
        setShowAlert(false);
      }, 3000); // Change the duration (in milliseconds) as per your requirement

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showAlert]);

  const handleStatusCheckbox = (value) => {
    const isSelected = locationFilteredStatus?.includes(value);
    // console.log(selectedValues1, isSelected);
    if (value === "all") {
      if (isSelected) {
        dispatch(saveLocationStatusFilter([]));
      } else {
        dispatch(saveLocationStatusFilter([...locationStatusFilter]));
      }
    } else {
      let newSelectedValues = [];
      if (isSelected) {
        newSelectedValues = locationFilteredStatus.filter((v) => v !== value);
      } else {
        console.log(locationStatusFilter, value);
        newSelectedValues = [...locationFilteredStatus, value];
      }
      // setSelectedValues(newSelectedValues);
      dispatch(saveLocationStatusFilter([newSelectedValues]));

      const areAllSelected = locationStatusFilter
        .filter((item) => item !== "all")
        .every((item) => newSelectedValues.includes(item));

      // Check the "All" checkbox if all others are selected
      if (areAllSelected) {
        // setSelectedValues((prevSelected) => [...prevSelected, "all"]);
        dispatch(saveLocationStatusFilter([...newSelectedValues, "all"]));
      } else {
        // Uncheck the "All" checkbox if any other checkbox is unselected
        // setSelectedValues((prevSelected) =>
        //   prevSelected.filter((item) => item !== "all")
        // );
        dispatch(
          saveLocationStatusFilter(
            newSelectedValues.filter((item) => item !== "all")
          )
        );
      }
    }
  };

  const HandleMembercheckbox = (value) => {
    const isSelected = selectedMembers.includes(value);
    if (isSelected) {
      const selected = selectedMembers.filter((item) => item !== value);
      console.log(selected);
      setSeletedMembers(selected);
      dispatch(saveLocationMembersFilter(selected));
    } else {
      const selected = [...selectedMembers, value];
      console.log(selected);
      setSeletedMembers(selected);
      dispatch(saveLocationMembersFilter(selected));
    }
  };

  React.useEffect(() => {
    getLanguagedataFunction(dispatch);
  }, [!ln]);

  useEffect(() => {
    getCurrentLoggedUser();
  }, [!loggedUser]);

  const getCurrentLoggedUser = async () => {
    const { data } = await authApi.get(`${GET_USER}/${userInfo._id}`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    setLoggedUser(data);
  };

  const getTeamMembersData = async () => {
    try {
      const { data } = await authApi.get(
        `${TEAM_MEMBERS_LIST}/${loggedUser.company}`,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );
      setTeamMembersList(data);
    } catch (error) {
      setShowAlert(true);
      setError(error.response.data.message);
    }
  };

  const clickClearAll = (name) => {
    if (name === "statusfilter") {
      console.log("hsdgfjgsdjhf");
      // setSelectedValues([]);
      dispatch(saveLocationStatusFilter([]));
    } else if (name === "membersFilter") {
      setSeletedMembers([]);
      dispatch(saveLocationMembersFilter([]));
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setAnchorEl1(null);
  };

  useEffect(() => {
    if (loggedUser) {
      getTeamMembersData();
    }
  }, [!teamMembersList, loggedUser]);

  const handleViewRoute = (e) => {
    e.stopPropagation();

    navigate(`/maps/location-view-route/${selected.slug.toLowerCase()}`);
  };

  const handleMenuclick = (event, name) => {
    name(event.currentTarget);
  };

  const handleMenuclick1 = (event, item) => {
    event.stopPropagation();
    
    console.log(item);
    setSelected(item);
    setOpenMenu(event.currentTarget);
  };

  const handleClose = () => {
    setOpenMenu(null);
  };

  const handleSetTimeRange = (item) => {
    console.log(item.selection);
    setState([item.selection]);
    // dispatch(saveLoctionRangeFilter([item.selection]));
  };

  return (
    <Table style={{ marginTop: "20px" }}>
      {ln ? (
        <TableHead className={classes.TableHead}>
          <TableRow className={classes.tableHeadRow}>
            <TableCell
              className={`${classes.tableHeadCell} ${classes.Tablecell1}`}
            >
              <span>{ln.team_member}</span>{" "}
              {!window.location.pathname.includes("my-team") && (
                <div>
                  <span
                    style={{ marginLeft: "5px" }}
                    onClick={(e) => handleMenuclick(e, setAnchorEl1)}
                  >
                    <FilterFunnelIcon />
                  </span>
                  <Menu
                    anchorEl={anchorEl1}
                    open={Boolean(anchorEl1)}
                    onClose={handleMenuClose}
                  >
                    <div className={classes.filterHeading}>
                      <h5>Filter</h5>
                      <span
                        className={classes.claerBtn}
                        onClick={() => clickClearAll("membersFilter")}
                      >
                        clear all
                      </span>
                    </div>
                    {teamMembersList &&
                      teamMembersList.length > 0 &&
                      teamMembersList.map((item, index) => {
                        return (
                          <MenuItem
                            key={index}
                            style={{
                              padding: "6px 25px 6px 0",
                              textTransform: "capitalize",
                            }}
                          >
                            {/* <Checkbox
                        checked={selectedValues.includes(item._id)}
                        onChange={() => HandleMembercheckbox(item._id)}
                      /> */}
                            <Form.Check
                              style={{
                                margin: 10,
                                marginRight: 15,
                              }}
                              checked={selectedMembers.includes(
                                item.user_id._id
                              )}
                              onChange={() =>
                                HandleMembercheckbox(item.user_id._id)
                              }
                              name="group1"
                              type="checkbox"
                            />
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </Menu>
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortName, "teamMember.name")
                    }
                  >
                    {sortName?.includes("desc") &&
                    sortName?.includes("teamMember.name") ? (
                      <OppositeSortArrawBtnIcons />
                    ) : (
                      <SortArrawBtnIcons />
                    )}
                  </span>
                </div>
              )}
            </TableCell>
            <TableCell
              className={`${classes.tableHeadCell} ${classes.Tablecell2}`}
            >
              <span>{ln.assigned_on}</span>{" "}
              {/* <span style={{ marginLeft: "5px" }} onClick={toggleCalendar}>
                <FilterFunnelIcon />
              </span>
              <Dialog open={showCalendar} onClose={toggleCalendar} className={classes.CalendarRangeContainer}>
                <DateRangePicker
                  onChange={item => handleSetTimeRange(item)}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={state}
                  direction="horizontal"
                />
              </Dialog> */}
              {!window.location.pathname.includes("my-team") && (
                <span
                  onClick={() =>
                    handleSortClick(dispatch, sortName, "assigned_on")
                  }
                >
                  {sortName?.includes("desc") &&
                  sortName?.includes("assigned_on") ? (
                    <OppositeSortArrawBtnIcons />
                  ) : (
                    <SortArrawBtnIcons />
                  )}
                </span>
              )}
            </TableCell>
            <TableCell
              className={`${classes.tableHeadCell} ${classes.Tablecell3}`}
            >
              {ln.title}
            </TableCell>
            <TableCell
              className={`${classes.tableHeadCell} ${classes.Tablecell4}`}
            >
              <span>{ln.status}</span>{" "}
              <span
                style={{ marginLeft: "5px" }}
                onClick={(e) => handleMenuclick(e, setAnchorEl)}
              >
                <FilterFunnelIcon />
              </span>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <div className={classes.filterHeading}>
                  <h5>Filter</h5>
                  <span
                    className={classes.claerBtn}
                    onClick={() => clickClearAll("statusfilter")}
                  >
                    clear all
                  </span>
                </div>
                {locationStatusFilter.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      style={{
                        padding: "6px 25px 6px 0",
                        textTransform: "capitalize",
                      }}
                    >
                      <Form.Check
                        style={{
                          margin: 10,
                          marginRight: 15,
                        }}
                        checked={locationFilteredStatus.includes(item)}
                        onChange={() => handleStatusCheckbox(item)}
                        name="group1"
                        type="checkbox"
                      />
                      {item.replaceAll("_", " ")}
                    </MenuItem>
                  );
                })}
              </Menu>
              {!window.location.pathname.includes("my-team") && (
                <span
                  onClick={() => handleSortClick(dispatch, sortName, "status")}
                >
                  {sortName?.includes("desc") &&
                  sortName?.includes("status") ? (
                    <OppositeSortArrawBtnIcons />
                  ) : (
                   ""
                  )}
                </span>
              )}
            </TableCell>
            <TableCell
              className={`${classes.tableHeadCell} ${classes.Tablecell5}`}
            >
              {ln.rejected_reason}
            </TableCell>
            <TableCell
              className={`${classes.tableHeadCell} ${classes.Tablecell6}`}
            >
              {ln.action}
            </TableCell>
          </TableRow>
        </TableHead>
      ) : null}
      {props.list && props.list.length > 0 ? (
        <TableBody className={classes.tableBody}>
          {props.list.map((item, index) => {
            return (
              <LocationListItem
                key={index}
                item={item}
                index={index}
                openMenu={openMenu}
                handleMenuclick1={handleMenuclick1}
                handleViewRoute={handleViewRoute}
                handleCardClick={props.handleCardClick}
                handleClose={handleClose}
                ln={ln}
              />
            );
          })}
        </TableBody>
      ) : "No data found"}
    </Table>
  );
};

export default MyTableBody;
