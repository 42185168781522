import React, { useEffect, useState } from "react";
import classes from "./location.module.css";
import {
  Card,
  CardContent,
  Avatar,
  Badge,
  Chip,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import {
  checkOnline,
  generateColor,
  getChipColor,
  stringAvatar,
} from "../../../utilities/commonFuntions";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import { LOCATION } from "../../../api-calls/api-urls";
import { useDispatch, useSelector } from "react-redux";
import { saveLocationList } from "../../../Redux/actions/location-action";

const LocationCard = ({
  item,
  index,
  handleCardClick,
  ln,
  fromDate,
  setUpdateState,
  toDate,
}) => {
  const [isOnline, setIsOnline] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { teamMembersList } = useSelector((state) => state.reducers.allUsers);

  const handleViewRoute = (event, item) => {
    event.stopPropagation(); // Prevent card click event from firing
    navigate(`/maps/location-view-route/${item.slug.toLowerCase()}`);
  };

  const handleNotReached = async (event, item) => {
    event.stopPropagation(); // Prevent card click event from firing
    const { data } = await authApi.put(
      `${LOCATION}/mark-not-reached/${item._id}`,
      {},
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );
    console.log(data);
    setUpdateState(data)
  };

  const getLocations = async () => {
    const userEntry = {};

    try {
      const { data } = await authApi.post(
        `${LOCATION}?start_date=${moment(fromDate).format(
          "YYYY-MM-DD"
        )}&end_date=${moment(toDate).format("YYYY-MM-DD")}&view_all=${true}`,
        userEntry,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );
      dispatch(saveLocationList(data));
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  // useEffect(() => {
  //   checkOnline( item?.teamMember?._id);
  // }, [teamMembersList, item]);

  return (
    <div key={index}>
      <Card
        key={index}
        className={classes.teamCard}
        onClick={(e) => handleCardClick(e, item)}
      >
        <CardContent
          sx={{ paddingBottom: "16px !important" }}
          className={classes.teamCardContent}
        >
          <div className={classes.locationGridCardHeader}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "10px",
              }}
            >
              <Badge
                color={
                  checkOnline(item?.teamMember?._id) === "checkin"
                    ? "success"
                    : "error"
                }
                badgeContent=" "
                // variant="dot"
                overlap="circular"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                className={classes.LocationOnlineBadge}
              >
                {item?.teamMember &&
                item?.teamMember?.profile_pic &&
                item?.teamMember?.profile_pic?.filename ? (
                  <Avatar
                    alt={item.teamMember.name.trim() || item.title}
                    src={`${process.env.REACT_APP_IMAGE_URL}/${item?.teamMember?.profile_pic?.destination}${item?.teamMember?.profile_pic?.filename}`}
                    sx={{ width: 44, height: 44 }}
                  />
                ) : (
                  <Avatar
                    {...stringAvatar(item.teamMember.name.trim() || item.title)}
                    sx={{
                      bgcolor: `${generateColor(
                        item.teamMember.name || item.title
                      )}`,
                      width: "44px",
                      height: "44px",
                    }}
                  />
                )}
              </Badge>

              <div className={classes.memberCardText}>
                <Typography className={classes.memberCardTextHeading}>
                  {item.title}
                </Typography>
              </div>
            </div>
            <Chip
              className={classes.locationStatusChip}
              label={item.status}
              size="small"
              style={getChipColor(item.status)}
            />
          </div>
          <hr className={classes.UnderlineHr} />
          <div className={classes.memberCardTextContainer}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Typography className={classes.form_lable}>
                  {ln.team_member}
                </Typography>
                <Typography className={classes.memberCardTextHeading}>
                  {item.teamMember.name}
                </Typography>
              </div>
              {item.status === "rejected" ? (
                <div>
                  <Typography className={classes.form_lable}>
                    {ln.rejected_reason}
                  </Typography>
                  <Typography className={classes.memberCardTextHeading}>
                    {item?.user_location?.rejection_notes.length > 11?`${item?.user_location?.rejection_notes.substring(0, 11)}...`:  item?.user_location?.rejection_notes}
                  </Typography>
                </div>
              ) : null}
            </div>
            <div>
              <Typography className={classes.form_lable}>
                {ln.assigned_on}
              </Typography>
              <Typography className={classes.memberCardTextHeading}>
                {moment(item.assigned_on).format("DD MMM YYYY,hh:mm A")}
              </Typography>
            </div>
          </div>
          <hr className={classes.UnderlineHr} />
          <div className={classes.memberCardBtnSection}>
            {item.status === "completed" ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  style={{
                    width: "45%",
                  }}
                  className={classes.locationCardButton}
                  onClick={(event) => handleViewRoute(event, item)}
                >
                  {ln.view_route}
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  size="small"
                  style={{
                    width: "45%",
                  }}
                  className={classes.locationCardButton}
                  onClick={(event) => handleNotReached(event, item)}
                >
                  {ln.not_reached}?
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  variant="outlined"
                  size="small"
                  fullWidth
                  className={classes.locationCardButton}
                  onClick={(event) => handleViewRoute(event, item)}
                >
                  {ln.view_route}
                </Button>
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default LocationCard;
